.Btn {
  display: inline-block;
  text-align: center;
  background: var(--white);
  color: var(--base) !important;
  padding: 18px 40px;
  outline: 0;
  border: 1px solid var(--white);
  cursor: pointer;
  text-decoration: none;
  font-size:13px;

  &__txt {
    text-transform: uppercase;
    color: inherit;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.1em;
  }

  &:hover {
    background-color: var(--white-70);
    border-color:var(--white-70);
  }

  // variations
  &.BtnBlanc {
    
    &:hover {
      background-color: var(--base);
      color:var(--white) !important;
    }
  }
  &.bg_blue {
    background: var(--base);
    color: var(--white) !important;
    border-color: var(--white);

    &:hover {
      background-color: var(--white);
      color:var(--base) !important;
    }
  }

  &.Btnv2,
  &[type="submit"] {
    border-color:var(--blue-20);
    background-color:transparent;

    &:hover {
      background-color: var(--base);
      border-color:var(--base);
      color: var(--white) !important;
    }
  }

  &.BtnList {
    background:none;border:1px solid var(--blue-20);
    border-radius:50px;font-weight:700;text-transform:uppercase;
    padding:10px 20px;font-size:11px;line-height:1.3;
    &:hover {
      background-color: var(--base);border-color:var(--blue-20);color: var(--white) !important;
    }
  }

  &.externalLink {
    .Btn__txt {
      position:relative;
      &:before,
      &:after {
        content:"";
        width:7px;height:7px;
        display:block;position:absolute;
        right:-12px;top:0;
        transition:var(--default-transition);
        background-size:contain;background-position:center;background-repeat:no-repeat;
      }
      &:before {
        background-image:url('/wp-content/themes/thefashionpact/src/imgs/SVG/externalLink.svg');
      }
      &:after {
        opacity:0;visibility:hidden;
        background-image:url('/wp-content/themes/thefashionpact/src/imgs/SVG/externalLink_blue.svg');
      }
    }

    &:hover .Btn__txt {
      &:before {
        opacity:0;visibility:hidden;
      }
      &:after {
        opacity:1;visibility:visible;
      }
    }
  }

  
}