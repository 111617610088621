@use 'sass:math';

@import 'app/require/mixins';
@import 'app/require/var';
@import 'app/require/normalize';
@import 'app/require/critical';
@import 'app/require/fonts';
@import 'app/require/utils';

@import 'app/skeleton/layout';
@import 'app/skeleton/fonts';
@import 'app/skeleton/header';
@import 'app/skeleton/footer';
@import 'app/skeleton/forms';
@import 'app/skeleton/visual';
@import 'app/skeleton/icon';

@import 'app/components/search';
@import 'app/components/brand';
@import 'app/components/btn';
@import 'app/components/anchors';
@import 'app/components/links';
@import 'app/components/outdated';
@import 'app/components/tarteaucitron';
@import 'app/components/copyright';
@import 'app/components/intro';
@import 'app/components/loader';
@import 'app/components/nav';
@import 'app/components/nav-toggle';
@import 'app/components/plyr';
@import 'app/components/swiper';
@import 'app/components/inview';
// @import "app/components/cursor";
@import 'app/components/overlay';
@import 'app/components/modal';
@import 'app/components/post-list';
@import 'app/components/accordeon';
@import 'app/components/committee-item';
@import 'app/components/vimeo-player';
@import 'app/components/our-work-schema';

@import 'app/blocks/BlockSearchResults';
@import 'app/blocks/BlockPageHeader';
@import 'app/blocks/BlockPageContent';
@import 'app/blocks/BlockSteeringCommittee';

@import 'app/pages/components';
@import 'app/pages/homepage';
@import 'app/pages/about-us';
@import 'app/pages/our-ecosystem';
@import 'app/pages/content-library';
@import 'app/pages/our-work';
@import 'app/pages/our-work-child';
@import 'app/pages/how-to-engage';
@import 'app/pages/faqs-contact';
@import 'app/pages/medias';
@import 'app/pages/single';
@import 'app/pages/legals';
@import 'app/pages/404';
@import 'app/pages/page-custom-password';

body {
	background: none;
	font-family: 'Aiglon Aaa', sans-serif;
}

body *:not(#Menu .Nav__link) {
	letter-spacing: -0.36px;
}

.hide {
	margin: 0 !important;
	padding: 0 !important;
	height: 0 !important;
	max-height: 0 !important;
	visibility: hidden !important;
	opacity: 0 !important;
}

@include responsive($mobile) {
	.mobile-only {
		display: none !important;
	}
}
@include responsive($mobile, max) {
	.notMobile {
		display: none !important;
	}
}

.--blue {
	background-color: #002060;
}
.--grey {
	background-color: var(--grey);
}
.--grey-l1 {
	background-color: #f5f8fa;
}
.--grey-l2 {
	background-color: #1e1e1e;
}

.--blue {
	color: var(--white);
}

.align-center {
	text-align: center;
}
.align-right {
	text-align: right;
}

.flex-center {
	display: flex;
	justify-content: center;
}

.align-items-start {
	align-items: flex-start;
}
.align-items-center {
	align-items: center;
}
.align-items-end {
	align-items: flex-end;
}

.justify-center {
	justify-content: center;
}
.space-between {
	justify-content: space-between;
}
.space-evenly {
	justify-content: space-evenly;
}

.padding20_00 {
	padding: 20px 0 0 0;
}

.padding60_0 {
	padding: 60px 0;
	@include responsive($tabletSmall, max) {
		padding: 30px 0;
	}
}

.text-underline {
	text-decoration: underline;
}

.tag--orange {
	color: var(--orange);
	font-weight: bold;
	font-size: 11px;
	line-height: 1.2em;
	border: 1px solid var(--orange);
	border-radius: 29px;
	text-transform: uppercase;
	overflow: hidden;

	span {
		padding: 6px 12px;
		white-space: nowrap;
	}
}

.section--rows-of-content {
	.row {
		margin-bottom: 80px;
		@include responsive($tabletSmall, max) {
			margin-bottom: 40px;
		}
		&:last-of-type {
			margin-bottom: 0 !important;
		}
		.visual__container {
			@include imgRatio(540, 480, var(--base));
		}
		> .col > .section--title {
			margin-bottom: 40px;
		}
	}
}

.container {
	.row {
		display: flex;
		flex-direction: row;
		width: 100%;
		flex-wrap: nowrap;
		position: relative;
		@include responsive($tabletSmall, max) {
			flex-direction: column;
		}

		&.resp_col-reverse {
			@include responsive($tabletSmall, max) {
				flex-direction: column-reverse;
				& > :first-child {
					margin-bottom: 0 !important;
				}
			}
		}

		@include responsive($tabletSmall, max) {
			& > * {
				margin-bottom: 30px;
			}
			&:not(.resp_col-reverse) > :last-child {
				margin-bottom: 0 !important;
			}
		}

		.col:first-of-type {
			padding-left: 0 !important;
		}
		.col:last-of-type {
			padding-right: 0 !important;
		}
		.col {
			padding-left: 15px;
			padding-right: 15px;
			@include responsive($tabletSmall, max) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}

		.col > * {
			margin-bottom: 25px;

			&.visual__container {
				@include imgRatio(540, 480, var(--base));

				@include responsive($tablet, max) {
					max-width: 450px;
				}

				@include responsive($mobile, max) {
					max-width: 100%;
				}

				@include responsive($tablet) {
					max-width: space(10);
				}
			}
		}
		.col > :last-child {
			margin-bottom: 0 !important;
		}
	}
	.row-vertical {
		display: flex;
		flex-direction: column;
		> .col {
			display: flex;
			flex-direction: column;
		}
	}
	.col1_2 {
		@include responsive($tablet, max) {
			align-items: center;
		}
		& > * {
			width: calc(100% / 2);
			@include responsive($tablet, max) {
				width: 100%;
			}
		}
	}
	.col1_3 {
		@include responsive($tablet, max) {
			align-items: center;
		}
		& > * {
			width: calc(100% / 3);
			@include responsive($tablet, max) {
				width: 100%;
			}
		}
	}
	.col1_4 {
		@include responsive($tablet, max) {
			align-items: center;
		}
		& > * {
			width: calc(100% / 4);
			@include responsive($tablet, max) {
				width: calc(100% / 2);
			}
		}
	}
	.col1_5 {
		@include responsive($tablet, max) {
			align-items: center;
		}
		& > * {
			width: calc(100% / 5);
		}
	}
	.col1_6 {
		@include responsive($tablet, max) {
			align-items: center;
		}
		& > * {
			width: calc(100% / 6);
		}
	}

	.w80per {
		width: 80%;
	}
	.w70per {
		width: 60%;
	}
	.w65per {
		width: 65%;
	}
	.w60per {
		width: 60%;
	}
	.w55per {
		width: 55%;
	}
	.w50per {
		width: 50%;
	}
	.w45per {
		width: 45%;
	}
	.w40per {
		width: 40%;
	}
	.w35per {
		width: 35%;
	}
	.w30per {
		width: 30%;
	}
	.w20per {
		width: 20%;
	}

	.margin_right_30per {
		margin-right: 30%;
	}
	.margin_left_30per {
		margin-left: 30%;
	}
	.margin_right_20per {
		margin-right: 20%;
	}
	.margin_left_20per {
		margin-left: 20%;
	}
	.margin_right_10per {
		margin-right: 10%;
	}
	.margin_left_10per {
		margin-left: 10%;
	}
	.padding_right_30per {
		padding-right: 30%;
	}
	.padding_left_30per {
		padding-left: 30%;
	}
	.padding_right_20per {
		padding-right: 20%;
	}
	.padding_left_20per {
		padding-left: 20%;
	}
	.padding_right_10per {
		padding-right: 10%;
	}
	.padding_left_10per {
		padding-left: 10%;
	}

	@include responsive($tabletSmall) {
		.maxw1200,
		.maxw1200 span {
			max-width: 1200px;
		}
		.maxw840,
		.maxw840 span {
			max-width: 840px;
		}
		.maxw480,
		.maxw480 span {
			max-width: 480px;
		}
		.maxw440,
		.maxw440 span {
			max-width: 440px;
		}
		.maxw420,
		.maxw420 span {
			max-width: 420px;
		}
	}

	.centered {
		justify-content: center;
		align-items: center;
	}
	@include responsive($tabletSmall) {
		.showMobile {
			display: none !important;
		}
	}

	@include responsive($desk, max) {
		.w45per,
		.w55per {
			width: 50%;
		}
	}
	@include responsive($tabletSmall, max) {
		.hideMobile {
			display: none !important;
		}
		.w100 {
			width: 100% !important;
		}
		.w90 {
			width: 90% !important;
		}
		.w80 {
			width: 80% !important;
		}
		.w70 {
			width: 70% !important;
		}
		.w60 {
			width: 60% !important;
		}
		.w50 {
			width: 50% !important;
		}
		.w40 {
			width: 40% !important;
		}
		.w30 {
			width: 30% !important;
		}
		.w20 {
			width: 20% !important;
		}
		.w10 {
			width: 10% !important;
		}
	}
}

.container-fullwidth {
	width: 100%;
	display: flex;
}
.row-fullwidth {
	width: 100%;
	height: auto;
	min-height: 100%;
	display: block;
	&.--bg {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
}

/*------- pages -------*/
.Page {
	h1.section--title,
	h2.section--title {
		line-height: 1em;
	}
	.container {
		padding-top: 100px;
		padding-bottom: 100px;
		@include responsive($tablet, max) {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		.row .col {
			.tag--orange {
				position: absolute;
				top: -32px;
			}
		}
	}
	.section--content {
		.row:last-of-type {
			margin-bottom: 20px !important;
		}
		.container .row .col span {
			> ul {
				padding-left: 20px;
				> li > strong {
					position: relative;
					&:before {
						content: '\2022';
						text-align: center;
						transition: all 0.4s ease-in-out;
						display: inline-block;
						height: 22px;
						line-height: 22px;
						position: absolute;
						top: 2px;
						bottom: 0;
						left: -20px;
					}
				}
			}
			> ol {
				list-style: decimal;
				padding-left: 25px;
			}
		}
	}
}

/*------- blocs -------*/
.breadcrumb-block {
	font-size: 13px;
	line-height: 15px;
	text-transform: uppercase;
	letter-spacing: 0 !important;
	a {
		position: relative;
		font-weight: 500;
		text-decoration: none !important;

		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 1px;
			background: var(--base);
			transform: scaleX(0);
			transform-origin: left center;
			transition: transform 400ms $easeOutQuad;
		}

		&.--active,
		&:focus,
		&:hover {
			&:after {
				transform: scaleX(1);
			}
		}
	}
	.breadcrumb_last {
		font-weight: 500;
		color: var(--base-70);
	}

	&.--textwhite {
		a {
			color: var(--white);
			&:after {
				background: var(--white);
			}
		}
		.breadcrumb_last {
			color: var(--white-70);
		}
	}
}

.section--swiper__quotes {
	.swiperCitations {
		overflow: hidden;

		@include responsive($tabletSmall) {
			padding-left: 280px;
			flex-direction: row;
			flex-wrap: nowrap;
			overflow: hidden;
		}

		.swiper-wrapper {
			.mySlide {
				padding: 0 20px;

				.swiper-quotes__text {
					font-size: 18px;
					line-height: 1.2;
					@include fluid-type($mobileSmall, $deskXL, 18px, 24px);
				}

				.swiper-quotes__separator {
					margin-top: 40px;
					margin-bottom: 30px;
					background-color: var(--darkblue-20);
					height: 2px;
					width: 100%;
				}
				.swiper-quotes__author {
					display: flex;

					.swiper-quotes__author--img {
						height: 60px;
						width: 60px;
						min-width: 60px;
						margin-right: 12px;
						border-radius: 50%;
						overflow: hidden;

						img {
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}

					.swiper-quotes__author--text {
						display: flex;
						flex-direction: column;
						justify-content: center;

						strong {
							font-size: 17px;
						}
						p {
							font-size: 15px;
						}
					}
				}
			}
		}
		.swiper-navigation {
			@include responsive($tabletSmall) {
				width: 280px;
				max-width: 280px;
				margin-bottom: 0;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				background-color: var(--grey);
				z-index: 2;
				justify-content: flex-start;
				align-items: flex-start;
			}

			> div {
				width: 56px;
				height: 56px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid var(--blue-20);
				border-radius: 50px;
				transition: var(--default-transition);
				font-size: 0;
				color: transparent;
				background-size: 8px 16px;
				background-position: center;
				background-repeat: no-repeat;

				&.prev {
					background-image: url('/wp-content/themes/thefashionpact/src/imgs/SVG/swiper-prev.svg');
				}
				&.next {
					margin-left: 15px;
					background-image: url('/wp-content/themes/thefashionpact/src/imgs/SVG/swiper-next.svg');
				}
				&:hover {
					opacity: 0.7;
				}
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				right: 0;
				top: 0;
				height: 35px;
				width: 47px;
				background-image: url('/wp-content/themes/thefashionpact/src/imgs/SVG/symbol_quote.svg');
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;

				@include responsive($tabletSmall, max) {
					right: auto;
					left: 0;
				}
			}
		}
	}
}

.swiper .swiper-navigation {
	@include responsive($tabletSmall) {
		width: 112;
		max-width: 112;
		margin-bottom: 0;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		z-index: 2;
		justify-content: flex-start;
		align-items: flex-start;
	}

	> div {
		width: 56px;
		height: 56px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid var(--blue-20);
		border-radius: 50px;
		transition: var(--default-transition);
		font-size: 0;
		color: transparent;
		background-size: 8px 16px;
		background-position: center;
		background-repeat: no-repeat;

		&.prev {
			background-image: url('/wp-content/themes/thefashionpact/src/imgs/SVG/swiper-prev.svg');
		}
		&.next {
			margin-left: 15px;
			background-image: url('/wp-content/themes/thefashionpact/src/imgs/SVG/swiper-next.svg');
		}
		&:hover {
			opacity: 0.7;
		}
	}
}

.section--tools-and-ressources {
	.tools-and-ressources--wrapper {
		width: 100%;
		.tools-and-ressources--filters {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 50px;

			@include responsive($mobile, max) {
				flex-direction: column;
				justify-content: center;
			}

			select {
				background-color: var(--white);
				color: var(--blue-60);
				width: max-content;
				min-width: 210px;
				border-radius: 10px;
				margin-right: 10px;
				margin-bottom: 10px;
				position: relative;
				font-size: 15px;
				font-weight: 300;
				color: var(--blue-60);

				background-image: url('/wp-content/themes/thefashionpact/src/imgs/SVG/arrow_bottom.svg');
				background-position: calc(100% - 15px) center;
				background-size: 9px 6px;
				background-repeat: no-repeat;

				white-space: nowrap;
				text-overflow: ellipsis;
				padding-right: 2em;

				&:last-of-type {
					margin-right: 0 !important;
				}

				@include responsive($mobile, max) {
					/*width:100%;*/
					margin-right: 0 !important;
					width: 100%;
				}

				@include responsive($mobile) {
					max-width: 210px;
				}
			}
		}
		.tools-and-ressources--results {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			@include responsive($tablet, max) {
				flex-direction: column;
				justify-content: center;
			}

			.tools-and-ressources--item {
				width: calc(50% - 15px);
				margin-right: 30px;
				margin-bottom: 30px;
				@include responsive($tablet) {
					max-width: 550px;
				}

				@include responsive($tablet, max) {
					width: 100%;
					margin-right: 0 !important;
				}
				&:nth-child(2n) {
					margin-right: 0 !important;
				}

				.tools-and-ressources--item__link {
					&, h3{
						font-size: 22px;
						font-weight: 400;
						margin-bottom: 0;
						line-height: 1.6;
						width: 100%;
						display: block;
						@include fluid-type($mobileSmall, $deskXL, 16px, 22px);
						position: relative;
						padding-right: 20px;
						transition: var(--default-transition);
					}

					&:after {
						content: '';
						background-image: url('/wp-content/themes/thefashionpact/src/imgs/SVG/link_ext.svg');
						background-position: center;
						background-size: contain;
						background-repeat: no-repeat;
						position: absolute;
						top: 0;
						left: auto;
						right: 0;
						bottom: auto;
						width: 11px;
						height: 9.5px;
						display: block;
						transition: var(--default-transition);
					}

					&:hover {
						opacity: 0.4;
						&:after {
							right: -5px;
							top: -5px;
						}
					}
				}
				.tools-and-ressources--item__excerpt {
					font-size: 15px;
					color: var(--blue-60);
					@include fluid-type($mobileSmall, $deskXL, 13px, 15px);
				}
			}
		}
	}
}

.section--member {
	.container {
		padding-top: 100px;
		padding-bottom: 100px;
		@include responsive($tablet, max) {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		@include responsive($mobile, max) {
			padding-top: 60px;
			padding-bottom: 60px;
		}
		.row {
			margin-bottom: 0 !important;

			.tag--orange {
				margin-bottom: 0 !important;
			}
		}
	}
}

.section--work-content {
	.container{
		padding-top: 2.5em;
		padding-bottom: 2.5em;
	}

	.WorkContent {
		padding: 5em 0;

		@include responsive($tabletSmall) {
			padding: 5em space(1);
		}

		&:nth-child(odd) {
			.head {
				@include responsive($tabletSmall) {
					flex-direction: row;
				}

				.visual__container {
					@include responsive($tabletSmall) {
						margin-left: auto;
					}
				}
			}
		}

		&:nth-child(even) {
			.head {
				@include responsive($tabletSmall) {
					flex-direction: row-reverse;
				}

				.wrapper {
					margin-left: auto;
				}
			}
		}

		.head {
			@include flex();
			gap: 3em;
			width: 100%;

			@include responsive($tabletSmall, max) {
				flex-direction: column-reverse;
			}

			.wrapper {
				@include flex(flex-start);
				flex-wrap: wrap;
				gap: 1em;

				@include responsive($tabletSmall) {
					max-width: get-vw(600);
				}

				@include responsive($deskXXL) {
					max-width: 510px;
				}

				h2 {
					text-transform: uppercase;

					@include responsive($tabletSmall) {
						@include font-vw(50);
					}

					@include responsive($deskXXL) {
						font-size: 50px;
					}
				}
			}
		}

		.visual__container {
			width: 100%;

			@include responsive($tabletSmall, max) {
				max-width: 70%;
			}

			@include responsive($tabletSmall) {
				max-width: get-vw(540);
			}

			@include responsive($deskXXL) {
				max-width: 540px;
			}
		}

		> .wswyg--content {
			margin-top: 3em;
		}
	}
}



/*
*	A11Y Plugin
*/
#pojo-a11y-toolbar{
	display: flex;
	align-items: flex-end;
	top: auto !important;
	bottom: 5% !important;

	.pojo-a11y-toolbar-toggle{
		width: 40px;
		height: 40px;

		.pojo-a11y-toolbar-link.pojo-a11y-toolbar-toggle-link{
			display: block;
			width: 100%;
			height: 100%;

			> svg{
				width: 20px;
			}
		}
	}
}
