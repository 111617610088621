// .accordeon_container input {
//   position: absolute;
//   opacity: 0;
//   z-index: -1;
// }

.accordeon_container {
  overflow: hidden;
}

.accordeon_row {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid var(--blue-20);
  padding: 15px;

  &:last-of-type {
    border-bottom: 1px solid var(--blue-20);
  }

  &--label {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    font-weight: bold;
    cursor: pointer;
    transition: all 500ms $ease;
    padding-right: 30px;
    width: 100%;

    .label--bull {
      width: 1em;
      height: 1em;
      margin-right: 3px;
      margin-top: 0.2em;

      &::before {
        content: "\2022";
        text-align: center;
        transition: all 500ms $ease;
        display: block;
        height: 22px;
        line-height: 16px;
      }

      @include responsive($mobile, max) {
        margin-right: 5px;
      }
    }
    /* Icon */
    &:hover {
      color: var(--blue-50);
    }

    &::before,
    &::after {
      width: 24px;
      height: 24px;
      text-align: center;
      transition: all 500ms $ease;
      position: absolute;
      left: auto;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      font-size: 40px;
      line-height: 6px;
      font-weight: 300;
      transform: rotate(-90deg);
    }
    
    &::before {
      content: "+";
    }

    &::after {
      content: "-";
      opacity: 0;
      visibility: hidden;
    }
  }

  &--content {
    max-height: 0;
    opacity: 0;
    padding: 0 22px;
    transition: opacity 500ms $ease,
                max-height 500ms $ease;

    .wswyg--content{
      padding: 0.75em 0 0.5em;
    }
  }
}

.accordeon_row.--active {
  > .accordeon_row--label{
    &:after,
    &:before {
      transform: rotate(0deg);
    }

    &:before {
      opacity: 0;
      visibility: hidden;
    }

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  > .accordeon_row--content {
    opacity: 1;

    @include responsive($mobile, max) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
