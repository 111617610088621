.Page.OurWork {
	.container-fullwidth {
		min-height: 665px;
		height: auto;

		@include responsive($desk, max) {
			min-height: 520px;
		}
		@include responsive($tablet, max) {
			min-height: 480px;
		}
		@include responsive($tabletSmall, max) {
			min-height: 380px;
		}
		@include responsive($mobile, max) {
			min-height: 280px;
		}
		@include responsive($mobileSmall, max) {
			min-height: 200px;
		}
	}
}

.section--mainAreas {
	.mainAreas-list--items {
		display: flex;
		justify-content: center;
		width: 100%;
		gap: 1em;

		@include responsive($tabletSmall, max) {
			flex-direction: column;
		}

		.mainAreas-list--item {
			perspective: 1000px;
			position: relative;
			width: 100%;
			margin-bottom: 30px;

			@include responsive($tabletSmall, max) {
				max-width: 450px;
				margin-left: auto;
				margin-right: auto;
			}

			@include responsive($tabletSmall) {
				width: 33.33333%;
			}

			&.--flipping {
				.inner {
					transform: rotateY(-180deg);
				}
			}

			.inner {
				display: flex;
				position: relative;
				transition: transform 0.6s;
				transform-style: preserve-3d;
				min-height: 450px;
				height: 100%;
				width: 100%;

				.front-side,
				.back-side {
					width: 100%;
					min-height: 100%;
					padding: 40px;
					-webkit-backface-visibility: hidden;
					backface-visibility: hidden;
				}

				.front-side {
					background-color: var(--grey);
					position: relative;

					.mainAreas-list--item__img {
						margin-bottom: 20px;
						padding: 10px;
						background-color: var(--white);
						border-radius: 50px;
						width: max-content;
						max-width: 100%;
					}

					.mainAreas-list--item__content {
						display: flex;
						flex-direction: column;

						> .mainAreas-list--item__title {
							margin-bottom: 10px;
							font-size: 42px;
							@include fluid-type($mobileSmall, $deskXL, 24px, 42px);
						}
						> .mainAreas-list--item__excerpt {
							font-size: 22px;
							@include fluid-type($mobileSmall, $deskXL, 16px, 22px);
						}
					}

					> button {
						margin-top: 2em;

						@include noTouchHover() {
							.Icon.--arrow {
								transform: translate(0%, 0%) rotate(-45deg) scale(1);
								opacity: 1;
							}
						}

						span {
							border-bottom: 1px solid var(--base);
						}

						.Icon.--arrow {
							position: absolute;
							bottom: 2em;
							right: 2em;
							transform: translate(-30%, 30%) rotate(-45deg) scale(2);
							opacity: 0;
							transition:
								transform 400ms $ease,
								opacity 400ms $ease;
						}
					}
				}

				.back-side {
					background-color: var(--base);
					color: var(--white);
					transform: rotateY(-180deg);
					position: absolute;
					top: 0;
					left: 0;

					.wswyg--content{
						*{
							color: var(--white);
						}

						> * + * {
							margin-top: 1em;
						}
					}

					> button {
						font-size: 0.7em;
						margin-bottom: 2em;
						border-bottom: 1px solid var(--white);
					}
				}
			}
		}
	}
}

.section--areas-of-action {
	.container {
		overflow: hidden;
		//padding-bottom: 5em;

		> .head {
			.swiper-wrapper{
				height: auto !important;
			}

			.swiper-slide {
				max-width: 500px;
			}

			.swiper-navigation{
				position: relative;
				inset: 0;
				justify-content: flex-end;
				margin-top: 2em;
				margin-bottom: 2em;
			}
		}

		> .content{
			display: grid;
			margin-top: 5em;
			grid-gap: 5em;

			@include responsive($tabletSmall){
				margin-top: 7.5em;
				grid-template-columns: repeat(2, 1fr);
			}
		}

		> .content{
			@include responsive($tabletSmall){
				grid-gap: 20%;
			}

			.float{
				width: 100%;

				@include responsive($tabletSmall, max){
					margin-bottom: 3em;
				}

				@include responsive($tabletSmall){
					float: left;
					padding: 2em;					
					max-width: 50%;
				}

				.visual__container{
					@include imgRatio(2000, 1125);

					> .visual{
						transform: none;

						img{
							height: 100%;
							object-fit: contain;
						}
					}
				}
			}
		}

		> .video_group{
			@include flex(center, center);
			flex-direction: column;
			margin-top: 4em;

			.wrapper{
				max-width: 40em;
				margin: 0 auto 2em;
				text-align: center;

				h3{
					@include responsive($tabletSmall){
						margin-bottom: 1em;
					}
				}

				.wswyg--content{
					text-align: center;
				}
			}

			.plyr__container{
				width: 100%;

				.plyr__play {
					background: var(--base);
					border-radius: 50%;
					max-width: 100px;

					.Icon{
						min-width: 30%;
						width: 30%;
					}
				}
			}
		}
	}

	.ourWork-list--item {
		display: flex;
		flex-direction: column;
		position: relative;

		.ourWork-list--item__img {
			margin-bottom: 20px;
			overflow: hidden;

			img {
				height: 235px;
				width: 100%;
				object-fit: cover;
				transition: var(--default-transition);
			}
			&:hover img {
				transform: scale(1.05);
			}
		}
		.ourWork-list--item__picto {
			position: absolute;
			width: 40px;
			height: 40px;
			top: 25px;
			right: 25px;
			pointer-events: none;
			&:before {
				content: '';
				position: absolute;
				top: -10px;
				left: -10px;
				width: calc(100% + 20px);
				height: calc(100% + 20px);
				background: rgba(255, 255, 255, 0.01);
				backdrop-filter: blur(25px);
				border-radius: 50px;
			}
		}
		.ourWork-list--item__content {
			display: flex;
			flex-direction: column;

			h3 {
				font-size:27px;
				 line-height:1.1em;
				 @include fluid-type($mobileSmall, $deskXL, 16px, 27px);
				text-transform: uppercase;
				margin-bottom: 15px;
			}

			.ourWork-list--item__excerpt {
				margin-bottom: 25px;
			}
		}
	}
}

.section--process {
	.container {
		.row + .row {
			margin-top: 80px;
		}

		.process--swipable-content {
			width: 100%;

			.process--swipable-content__bloc {
				display: flex;
				flex-wrap: nowrap;
				margin-bottom: 80px;
				@include responsive($tabletSmall, max) {
					flex-direction: column;
					margin-bottom: 60px;
				}
				.process--swipable-content__item {
					width: calc(25% - 28px);
					line-height: 1.2;
					margin-right: 28px;
					position: relative;

					&:last-of-type {
						margin-right: 0 !important;
					}

					@include responsive($tabletSmall, max) {
						width: 100%;
						min-height: 100px;
						padding-left: 40px;
						margin-bottom: 34px;
						display: flex;
						align-items: center;
						margin-right: 0;
						&:last-of-type {
							margin-bottom: 0 !important;
						}
					}

					.process--swipable-content__item--text {
						font-size: 22px;
						@include fluid-type($mobileSmall, $deskXL, 17px, 22px);
					}
				}
			}

			.process--swipable-content__footer {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				@include responsive($tablet, max) {
					flex-direction: column;
				}
				&:before,
				&:after {
					content: '';
					height: 1px;
					width: 20%;
					max-width: 240px;
					background-color: var(--blue-20);
					display: block;
					position: relative;
					@include responsive($tablet, max) {
						width: 80%;
						max-width: none;
					}
				}
				.process--swipable-content__footer--textContainer {
					min-width: calc(100% - 480px);
					width: 60%;
					@include responsive($tablet, max) {
						padding: 30px 0;
						width: 90%;
						min-width: unset;
					}
					@include responsive($mobile, max) {
						width: 100%;
					}
					display: flex;
					flex-direction: column;
					align-items: center;
					.process--swipable-content__footer--text {
						opacity: 0.5;
						margin-bottom: 12px;
					}
					.process--swipable-content__footer--title {
						line-height: 1.3;
						font-size: 42px;
						@include fluid-type($mobileSmall, $deskXL, 24px, 42px);
					}
				}
			}
		}

		&.container_video {
			padding-top: 0px !important;
		}
	}

	.container {
		@include responsive($tabletSmall) {
			height: 250dvh;
		}
	}

	.sticky {
		position: sticky;
		top: 1em;
	}

	.process--timeline {
		margin-bottom: 5em;

		> .head,
		> .steps {
			width: 100%;
			display: grid;

			@include responsive($tablet) {
				grid-template-columns: repeat(4, 1fr);
			}
		}

		> .head {
			margin-bottom: 3em;

			@include responsive($tablet, max) {
				display: none;
			}

			.head-cell {
				@include flex(center);
				position: relative;

				&:not(:first-child) {
					&:before,
					&:after {
						@include content();
						height: 1px;
						width: 100%;
						position: absolute;
						top: 50%;
						right: 100%;
						transform: translate(0, -50%);
						pointer-events: none;
						z-index: 0;
						transition: transform 350ms var(--ease-in-out-circ);
					}

					&:before {
						background: #e1e1e1;
					}

					&:after {
						background: var(--base);
						transform-origin: 0% 0%;
					}
				}

				&:not(.--active) {
					button {
						background: #e1e1e1;
						color: #818181;
					}

					&:after {
						transform: translate(0, -50%) scaleX(0);
					}
				}

				> span {
					@include flex(flex-start, center);
					height: get-vw(30);
					width: get-vw(30);
					border-radius: 50%;
					background: var(--base);
					position: relative;
					z-index: 1;
					color: var(--white);
					transition:
						color 350ms var(--ease-in-out-circ),
						background 350ms var(--ease-in-out-circ);

					@include responsive($tablet, max) {
						min-width: 30px;
						min-height: 30px;
					}

					@include responsive($tablet) {
						font-size: 1.2vw;
					}

					@include responsive($deskXXL) {
						max-width: 20px;
						max-height: 20px;
						font-size: 12px;
					}
				}
			}
		}

		> .steps {
			@include responsive($tablet, max) {
				grid-gap: 2em;
			}

			.process--step {
				transition:
					transform 350ms var(--ease-in-out-circ),
					opacity 350ms var(--ease-in-out-circ);

				@include responsive($tablet, max) {
					@include flex(flex-start);
				}

				@include responsive($tablet) {
					max-width: 90%;
				}

				&:before {
					content: attr(data-step);
					@include flex(flex-start, center);
					height: 30px;
					width: 30px;
					border-radius: 50%;
					background: var(--base);
					color: var(--white);

					@include responsive($tablet) {
						display: none;
					}
				}

				&:not(.--active) {
					@include responsive($tablet) {
						opacity: 0.1;
						transform: translate(0, 15%);
					}
				}

				.process--swipable-content__item--text {
					@include responsive($tablet, max) {
						flex: 1;
						padding-left: 1em;
					}
				}
			}
		}
	}
}

.section--industry-progress {
	position: relative;

	.industry-progress--bg {
		pointer-events: none;
		position: absolute;
		width: 60%;
		height: 100%;
		z-index: 1;
		background-position: center;
		opacity: 0.1;
		background-size: contain;
		background-repeat: no-repeat;

		@include responsive($tabletSmall, max) {
			display: none !important;
		}
	}

	.industry-progress--wrapper {
		overflow: hidden;

		.industry-progress--menu__items {
			display: flex;
			flex-wrap: nowrap;
			margin-bottom: 30px;

			.industry-progress--menu__item {
				cursor: pointer;
				margin-right: 20px;
				opacity: 0.3;
				position: relative;
				transition: var(--default-transition);

				&:last-of-type {
					margin-right: 0 !important;
				}

				&:after {
					content: '';
					background-color: var(--white);
					width: 0;
					height: 1px;
					position: absolute;
					bottom: 0;
					left: 0;
					transition: var(--default-transition);
				}

				&:hover:after,
				&.--active:after {
					width: 100%;
				}

				&.--active {
					opacity: 1;
				}
			}
		}

		.tabs-container {
			position: relative;

			.tab {
				&.--active {
					transition:
						opacity 500ms var(--ease-in-out-circ),
						transform 500ms var(--ease-in-out-circ);
				}

				&:not(.--active) {
					opacity: 0;
					pointer-events: none;
					position: absolute;
					transform: translate(0, 10%);
				}

				.percentage {
					&.--empty {
						&:not(:first-child) {
							margin-top: 3em;
						}
					}

					.number {
						@include flex(flex-start);
						font-size: 50px;
						font-weight: 300;

						@include responsive($tabletSmall) {
							@include font-vw(90);
						}

						@include responsive($deskXXL) {
							font-size: 90px;
						}
					}

					a{
						font-weight: 500;
						color: var(--white);
					}
				}

				> .wswyg--content:not(.related-joint-actions){
					margin-top: 30px;
					opacity: 0.5;

					* + *{
						margin-top: 0.5em;
					}
					
					*{
						font-weight: 300;
						font-size: 12px;
						color: var(--white);
						@include fluid-type($mobileSmall, $deskXL, 9px, 12px);
					}
				}

				> .wswyg--content.related-joint-actions{
					padding-top: 3em;
					margin-top: 3em;
					border-top: 1px solid rgba(255, 255, 255, 0.25);

					a{
						color: var(--white);
					}
				}
			}
		}
	}

	.cols2 {
		.w50per {
			.section--title {
				margin-bottom: 55px;
				@include responsive($tablet, max) {
					margin-bottom: 40px;
				}
				@include responsive($tablet, max) {
					margin-bottom: 25px;
				}
			}
		}
	}

	div.--content {
		.wswyg--content {
			p,
			a {
				color: var(--white);
			}
		}
	}
}

.container {
	.section--title {
		text-transform: uppercase;
		margin-bottom: 60px;
	}
	.row .section--title {
		margin-bottom: 60px;
	}

	.row {
		margin-bottom: 40px;
	}
}
