.SteeringCommittee {
  background: var(--base);
  
  @include responsive($tabletSmall){
    padding: get-vw(50) 0;
  }

  .container{
    .Page &{
      padding-top: 0; 
      padding-bottom: 0;
    }
  }

  .swiper-container {
    position: relative;
    margin-top: 3.5em;

    .swiper {
      overflow: hidden;
      
      @include responsive($tablet){
        max-width: 80%;
        margin: 0 auto;
      }

      .swiper-wrapper {
        @include responsive($tablet, max) {
          flex-direction: column;
          cursor: initial;
          gap: 3em;
        }
      }

      .swiper-slide {
        overflow: hidden;

        &:nth-child(1) {
          .visual__container {
            @include imgRatio(1940, 1100);
          }
        }

        &:nth-child(2) {
          @include flex(center, center);

          .wswyg--content {
            color: var(--white);
            text-align: center;

            @include responsive($tablet){
              padding: 3.5em;
            }
          }
        }
      }
    }

    .swiper-navigation {
      @include flex(center, space-between);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 95%;

      @include responsive($tablet, max) {
        display: none;
      }

      > button {
        @include flex(center, center);
        width: get-vw(50);
        height: get-vw(50);
        border-radius: 50%;
        background: var(--white);
        transition: transform 400ms $easeOutQuad;

        @include responsive($tablet, max) {
          min-width: 50px;
          min-height: 50px;
        }

        @include noTouchHover() {
          transform: scale(0.9);
        }

        &:first-child {
          .Icon.--arrow {
            transform: scaleX(-1);
          }
        }
      }
    }
  }

  .captions {
    text-align: center;
    color: var(--white);
    margin-top: 20px;

    > h2 {
      font-size: 20px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    > span {
      filter: opacity(0.5);
    }
  }
}
