@import url("https://use.typekit.net/spn3ibf.css");

@font-face {
  font-family: 'Aiglon Aaa';
  font-weight: 100;
  font-style: normal;
  font-display: block;
  src: url('../fonts/AiglonAaa-Thin.woff') format("woff"), url('../fonts/AiglonAaa-Thin.woff2') format("woff2");
}

@font-face {
  font-family: 'Aiglon Aaa';
  font-weight: 300;
  font-style: normal;
  font-display: block;
  src: url('../fonts/AiglonAaa-Light.woff') format("woff"), url('../fonts/AiglonAaa-Thin.Light') format("woff2");
}

@font-face {
  font-family: 'Aiglon Aaa';
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url('../fonts/AiglonAaa-Regular.woff') format("woff"), url('../fonts/AiglonAaa-Regular.woff2') format("woff2");
}

@font-face {
  font-family: 'Aiglon Aaa';
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url('../fonts/AiglonAaa-ExtraBold.woff') format("woff"), url('../fonts/AiglonAaa-ExtraBold.woff2') format("woff2");
}
// @font-face {
//   font-family: 'Aiglon Aaa';
//   font-weight: 900;
//   font-style: normal;
//   font-display: block;
//   src: url('../fonts/AiglonAaa-ExtraBold.woff') format("woff"), url('../fonts/AiglonAaa-ExtraBold.woff2') format("woff2");
// }