.Page__404 {
	text-align: center;

	@include responsive($tabletSmall){
		padding-top: 180px;
	}

	.section--title {
		text-align: center!important;
		margin-bottom: 10px;
	}

	.Btn {
		margin-top: 40px;
	}
}