.Legals {
  .page__header--container {
    .container > .row {
      padding-bottom: 5em;
      border-bottom: 1px solid rgba(#080808, 0.1);
    }

    nav > ul {
      @include flex();
      gap: 1em;

      a{
        @include noTouchHover() {
          > span {
            background-size: 100% 1px;
            background-position: 0% 100%;
          }
        }

        span {
          display: inline;
          background-position: 100% 100%;
          background-repeat: no-repeat;
          background-size: 0% 1px;
          transition: background-size 600ms var(--ease-in-out-circ);
          background-image: linear-gradient(var(--base), var(--base));
        }
      }
    }
  }

  section {
    .container {
      @include flex(flex-start);

      @include responsive($tablet, max) {
        flex-direction: column;
      }

      > * {
        width: 100%;
      }

      > h2 {
        font-size: 3.5em;

        @include responsive($tablet) {
          position: sticky;
          top: 1em;
          max-width: 35%;
        }
      }

      > .wswyg--content {
        padding-top: 1.5em;

        @include responsive($tablet){
          max-width: 55%;
          margin-left: auto;
        }
      }
    }
  }
}
