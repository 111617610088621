.Link {
  position: relative;
  font-size: 24px;

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--base);
    transform-origin: right center;
    transition: transform 400ms $easeInOutQuad 50ms;
  }

  &:before {
    transform: scaleX(0);
    transform-origin: left center;
    transition-delay: 0;
  }

  &:hover {
    &:before {
      transform: scaleX(1);
      transition-delay: 250ms;
    }

    &:after {
      transform: scaleX(0);
      transition-delay: 0;
    }
  }

  &.--min {
    font-size: 16px;
    font-weight: 500;
  }
}