.homepage .Header {
	position: absolute;

	.container {
		.Brand {
			.logo.logo-dark {
				display: none !important;
			}
			.logo:not(.logo-dark) {
				display: block !important;
			}
		}
		.Nav__toggle .line {
			background: var(--white);
		}
		.Nav__container .Nav__item > .Nav__link {
			color: var(--white);
			&:after {
				background: var(--white);
			}
		}
		.language-toggle,
		.language-toggle a {
			color: var(--white);
			&:after {
				background: var(--white);
			}
		}
	}
}

.Home {
	.home__header--container {
		height: 100dvh;
		min-height: 650px;
		max-height: 900px;
		position: relative;
		background: var(--base);
		overflow: hidden;

		.home__header--visual {
			&, &:after{
				@include cover();
			}

			&:after {
				@include content();
				background: rgba(0, 0, 0, 0.5);
			}
		}

		.home__header--content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;

			h1 {
				color: var(--white);
				line-height: 1.1em;
				font-family: var(--main-font);
				text-transform: uppercase;
				text-align: center;
				font-weight: 700;
				// max-width: 953px;
				margin: 0 auto 40px auto;
				hyphens: none;
				@include fluid-type($mobileSmall, $deskXL, 30px, 65px);

				@media (max-height: 550px) {
					// margin-bottom: 20px;
					// max-width: 928px;
					// @include fluid-type($mobileSmall, $deskXL, 38px, 78px);
					// @include fluid-type($mobileSmall, $deskXL, 38px, 50px);
				}

				@include responsive($tabletSmall, max) {
					max-width: 90%;
				}

				br {
					@include responsive($tabletSmall, max) {
						display: none;
					}
				}
			}
		}

		.container {
			max-width: 100%;
		}

		.home__header--instructions {
			position: absolute;
			bottom: 0;
			top: auto;
			left: 0;
			width: 100%;
			height: 95px;
			padding: 40px;
			display: flex;
			justify-content: center;

			@media (max-height: 550px) {
				padding: 20px 40px;
				height: 55px;
			}
			
			.home__header--instruction {
				font-size: 11px;
				line-height: 15px;
				color: var(--white);
				text-transform: uppercase;
				@include responsive($tablet, max) {
					&:nth-child(3) {
						display: none;
					}
				}
				@include responsive($tabletSmall, max) {
					&:nth-child(2) {
						display: none;
					}
				}
			}
			@include responsive($tabletSmall, max) {
				justify-content: center;
			}
		}
	}

	.container {
		.section--title {
			text-transform: uppercase;
			margin-bottom: 60px;
		}
		.row .section--title {
			margin-bottom: 60px;
		}

		.row {
			margin-bottom: 40px;
		}
	}

	div:not(.section--member) > .container {
		padding-top: 120px;
		padding-bottom: 120px;
		@include responsive($tablet, max) {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		@include responsive($mobile, max) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}

	.section--text {
		.container {
			padding-top: 180px;
			padding-bottom: 180px;
			@include responsive($tablet, max) {
				padding-top: 100px;
				padding-bottom: 100px;
			}
			@include responsive($mobile, max) {
				padding-top: 60px;
				padding-bottom: 60px;
			}

			p {
				max-width: 986px;
				margin: 0 auto;
				font-size: 40px;
				line-height: 1.2em;
				@include fluid-type($mobileSmall, $deskXL, 27px, 40px);

				span[style*='text-decoration:underline'],
				span[style*='text-decoration: underline'] {
					position: relative;
					text-decoration: none !important;
					&:after {
						content: '';
						position: absolute;
						bottom: -4px;
						height: 2px;
						width: 100%;
						display: block;
						left: 0;
						right: 0;
						top: auto;
						background-color: var(--base);
					}
				}
			}
		}
	}

	.section--cards {
		.container > p{
			margin-bottom: 4em;
			
			@include responsive($tabletSmall){
				max-width: 70%;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.cards {
			display: flex;
			justify-content: center;
			margin-bottom: 25px;
			gap: 1em;

			@include responsive($tabletSmall, max) {
				flex-direction: column;
				align-items: center;
			}

			&[data-inview] {
				&:not(.--visible) > * {
					opacity: 1;
					transform: translateY(50%) rotate(10deg);
				}

				&.--visible > * {
					&:nth-child(1),
					&:nth-child(3) {
						transition-delay: 0.1s;
					}
				}
			}

			.card {
				width: 100%;
				margin-top: 70px;
				margin-bottom: 30px;
				padding: 20px 40px 60px 40px;
				display: flex;
				flex-direction: column;
				align-items: center;
				background-size: 100% 100%;
				background-position: center;
				background-repeat: no-repeat;
				transform-origin: top center;
				transition: transform 1200ms $ease;

				@include responsive($tabletSmall){
					margin-right: 30px;
				}

				@include responsive($desk, max) {
					padding: 20px 30px 60px 30px;
				}

				@include responsive($mobile, max) {
					// margin-right: 0 !important;
					width: 100%;
					max-width: 380px;
				}

				@include responsive($tabletSmall) {
					max-width: 33.33333%;
				}

				@include noTouchHover() {
					transform: rotate(3deg);
					transition-delay: 0s;

					img {
						transform: scale(0.95);
					}
				}

				&:nth-child(2n) {
					@include noTouchHover() {
						transform: rotate(-3deg);
					}
				}

				.card--header {
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 30px;
					width: 100%;
					max-width: 240px;

					.circle {
						background-color: var(--white);
						width: 24px;
						height: 24px;
						border-radius: 50%;
						position: relative;
						margin-bottom: 20px;

						&:after {
							pointer-events: none;
							content: '';
							display: block;
							position: absolute;
							// width: 110px;
							// height: 103px;
							// left: 15px;
							// bottom: 15px;
							width: 43px;
							height: 140px;
							bottom: 100%;
							left: 50%;
							transform: translate(-50%, 0);
							background-repeat: no-repeat;
							background-position: center;
							background-size: contain;
						}
					}

					> img {
						max-width: 90%;
						max-height: 200px;
						transition: transform 820ms $ease;
					}
				}

				.card--content {
					h4 {
						margin-bottom:15px;
						 font-size:32px;
						 line-height:1.1em;
						 @include fluid-type($mobileSmall, $deskXL, 19px, 32px);
						text-transform: uppercase;
					}
				}

				&.card1 {
					background-image: url('/wp-content/themes/thefashionpact/src/imgs/card1_bg.png');

					.circle:after {
						// background-image: url('/wp-content/themes/thefashionpact/src/imgs/card1_boucle.svg');
						background-image: url('/wp-content/themes/thefashionpact/src/imgs/card1_ficelle.svg');
						transform: translate(-30%, 6%);
					}
				}

				&.card2 {
					background-image: url('/wp-content/themes/thefashionpact/src/imgs/card2_bg.png');

					.circle:after {
						width: 65px;
						// left: 0;
						// bottom: 9px;
						// background-image: url('/wp-content/themes/thefashionpact/src/imgs/card2_boucle.svg');
						background-image: url('/wp-content/themes/thefashionpact/src/imgs/card2_ficelle.svg');
						transform: translate(-85%, 6%);
					}
				}
				
				&.card3 {
					background-image: url('/wp-content/themes/thefashionpact/src/imgs/card3_bg.png');
					
					.circle:after {
						// left: auto;
						// bottom: 10px;
						// right: 15px;
						// background-image: url('/wp-content/themes/thefashionpact/src/imgs/card3_boucle.svg');
						background-image: url('/wp-content/themes/thefashionpact/src/imgs/card3_ficelle.svg');
						transform: translate(-20%, 6.5%);
					}
				}
			}
		}
	}

	.section--ecosystem {
		.row {
			p {
				margin-bottom: 30px;
			}
		}

		.partners-name {
			margin-top: 60px;
			pointer-events: none;
			font-size: 32px;
			color: var(--blue-l);
			@include fluid-type($mobileSmall, $deskXL, 23px, 32px);
			position: relative;

			&:not(.--too-big) {
				& ~ #LoadMoreBrands {
					display: none;
				}
			}

			&.--too-big {
				max-height: 350px;
				overflow: hidden;

				&:after {
					@include content();
					position: absolute;
					bottom: 0;
					left: 0;
					height: 100px;
					width: 100%;
					background: linear-gradient(180deg, rgba(#f2f6f7, 0) 0%, #f2f6f7 100%);
				}

				& ~ #LoadMoreBrands {
					.Btn {
						> span:first-child {
							display: block;
						}
						> span:nth-child(2) {
							display: none;
						}
					}
				}
			}

			&.--active {
				max-height: none;

				&:after {
					display: none;
				}

				& ~ #LoadMoreBrands {
					.Btn {
						> span:first-child {
							display: none;
						}
						> span:nth-child(2) {
							display: block;
						}
					}
				}
			}

			&[data-inview] {
				&:not(.--visible) {
					opacity: 0;
					> * {
						opacity: 1;
						transform: none;
					}
				}

				&.--visible {
					transition: opacity 640ms linear 240ms;
				}
			}

			> * {
				margin-bottom: 10px;
			}

			a.partner-name {
				position: relative;
				pointer-events: all;
				&:after {
					content: '';
					width: 0%;
					height: 1px;
					display: block;
					position: absolute;
					top: auto;
					bottom: 0;
					left: 0;
					right: auto;
					background-color: var(--blue-l);
					transition: var(--default-transition);
				}
				&:hover:after {
					width: 100%;
					background-color: var(--base);
				}
			}

			.partner-name {
				cursor: default;
				pointer-events: all;
				color: var(--blue-l);
				transition: var(--default-transition);
				&:hover {
					color: var(--base);
				}
			}

			.partner-separator {
				&:last-of-type {
					display: none;
				}
			}
		}

		#LoadMoreBrands {
			@include flex(center, center);
			margin-top: 2em;

			.Btn {
				> span:first-child {
					display: block;
				}
				> span:nth-child(2) {
					display: none;
				}
			}
		}
	}

	.section--player {
		.row.cols2 {
			margin-bottom: 70px;

			@include responsive($tabletSmall, max) {
				margin-bottom: 40px;
			}
		}

		.VimeoPlayer,
		.player {
			max-width: 850px;
			margin: 0 auto;
		}

		.player{
			.plyr__container{
				@include noTouchHover() {
					.visual__container{
						transform: none;
					}
				}
			}
		}
	}

	.section--articles {
		> .container > .row:not(.showMobile) {
			margin-bottom: 64px;
			@include responsive($tabletSmall, max) {
				margin-bottom: 40px;
			}

			.posts-list--items {
				margin-bottom: 0;
			}
		}

		.container > .head{
			.col.content{
				@include responsive($tabletSmall){
					max-width: 40%;
					margin-left: auto;
				}
			}
		}

		.read-more{
			@include flex(center, center);		
		}
	}
}
