.SearchResults {
  padding: 1.5em 0;

  &:not(:first-child){
    border-top: 1px solid rgba(#002060, 0.1);

    .results-number{
      display: none;
    }
  }

  .results-number {
    margin-bottom: 3em;
    color: rgba(#002060, 0.5);

    strong {
      // color: var(--white);
      color: var(--base);
    }
  }

  .results-grid {
    display: grid;
    grid-gap: 1.5em;
  }

  .result-preview {
    &:not(:only-child) {
      &:not(:last-child) {
        padding-bottom: 1.5em;
        // border-bottom: 1px solid rgba(white, 0.1);
        border-bottom: 1px solid rgba(#002060, 0.1);
      }
    }

    a,
    .p1 {
      font-weight: 700;
      font-size: 18px;
      line-height: 1.3;
      @include fluid-type($mobileSmall, $deskXL, 18px, 24px);

      & + .p2{
        margin-top: 1em;
      }
    }

    .p2 {
      font-size: 15px;
      // color: rgba(white, 0.5);
      color: rgba(#002060, 0.5);
      margin-top: 0.35em;

      strong {
        // color: var(--white);
        color: var(--base);
      }
    }
  }
}
