.Page.HowToEngage {
    .container-fullwidth {
        min-height: 665px;
        height: auto;

        @include responsive($desk, max) {
            min-height: 520px;
        }
        @include responsive($tablet, max) {
            min-height: 480px;
        }
        @include responsive($tabletSmall, max) {
            min-height: 380px;
        }
        @include responsive($mobile, max) {
            min-height: 280px;
        }
        @include responsive($mobileSmall, max) {
            min-height: 200px;
        }
    }
}

.section--steps {
    overflow: hidden;

    .container{
        .Page &{
            padding-bottom: 20px;
        }
    }

    .cols2 {
        > .w40per span {
            @include responsive($tablet) {
                max-width: 440px;
            }
        }
    }

    @include responsive($desk) {
        .container-swiperSteps {
            display: none !important;
        }
    }

    @include responsive($desk, max) {
        .row.steps--items {
            // display:none !important;
            overflow-x: auto;
            flex-direction: row;
            width: calc(100% + 60px);
            margin-left: -30px;
            margin-right: -30px;
            padding-left: 30px;
            padding-right: 30px;
            
            & > .steps--item {
                margin-bottom: 15px;
            }
        }
    }

    .scroll-indicator-mobile{
        @include flex(center, center);
        gap: 1em;
        font-size: 16px;
        color: var(--white);
        margin-top: 2em;

        @include responsive($tablet){
          display: none;
        }
    }

    .container-swiperSteps .swiperSteps {
        overflow: hidden;
        .swiper-slide {
            width: max-content;
        }
    }

    .container-swiperSteps .swiper-slide,
    .steps--items {
        margin-top: 90px;
        margin-bottom: 15px;
        position: relative;
        & > .steps--item {
            position: relative;
            min-width: 203px;
            max-width: 213px;
            width: calc(100% / 6);
            overflow: hidden;
            .steps--item__nav {
                position: relative;
                margin-bottom: 22px;
                display: flex;
                justify-content: center;
                cursor: pointer;
                background-color: var(--base);

                .steps--item__nav-text {
                    opacity: 0.5;
                    font-size: 36px;
                    line-height: 50px;
                    width: 65px;
                    height: 65px;
                    margin-bottom: 31px;
                    border: 1px solid var(--white);
                    border-radius: 50px;
                    z-index: 8;
                    transition: var(--default-transition);
                }

                > * {
                    // z-index:12;
                }

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: auto;
                    z-index: 2;
                    width: 100%;
                    height: calc(100% - 1px);
                    background-color: var(--base);
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: auto;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background-color: var(--white);
                }
            }
            .steps--item__content {
                line-height: 1.2 !important;
                max-width: 90%;
                margin: 0 auto;

                .steps--item__title {
                    font-weight: 700;
                    opacity: 0.5;
                    transition: var(--default-transition);
                }

                .steps--item__text {
                    opacity: 0;
                    transform: translateY(30%);
                    transition: var(--default-transition);
                }
            }

            &:hover {
                .steps--item__nav-text,
                .steps--item__title {
                    opacity: 1;
                }

                &:after {
                    top: 87px;
                }
            }

            &.--active {
                .steps--item__nav {
                    .steps--item__nav-text {
                        font-size: 45px;
                        line-height: 66px;
                        margin-bottom: 16px;
                        opacity: 1;
                        width: 80px;
                        height: 80px;
                    }
                }
                .steps--item__content {
                    .steps--item__title {
                        opacity: 1;
                    }
                    .steps--item__text {
                        opacity: 1;
                        transform: translateY(0%);
                    }
                }
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 80px;
                display: block;
                // background-color:var(--base);z-index:11;opacity:.4;
                width: 203px;
                height: 14.74px;
                margin: 0 auto;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("/wp-content/themes/thefashionpact/src/imgs/SVG/animated_line_v6.svg");
                transition: all 0.5s ease-in-out;
            }
            &.--active:after {
                top: 94px;
            }
        }
    }
}

.section--additional-content{
    background: var(--grey-3);

    .wswyg--content{
        margin-top: 4em;
    }
}

.section--fees {
    .fees--first-par {
        max-width: 1100px;
        margin: 0 auto;
    }
    .fees-numbers {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
    }
    .fees--second-par {
        max-width: 840px;
        margin: 0 auto;
    }
}

.section--contact-form {
    .container {
        .form--wrapper {
            padding-top: 20px;
            margin-bottom: 20px;

            .linkedInput-for-hearAbout-segment_field_5 {
                label {
                    margin: 0 !important;
                    padding: 0 !important;
                    height: 0 !important;
                    opacity: 0;
                    visibility: hidden;
                }
            }

            form > p {
                font-size: 0.7em;
                opacity: 0.7;
                margin-bottom: 5em;

                @include responsive($tabletSmall) {
                    text-align: center;
                }

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}

.section--content{
    .--visual-top{
        align-self: flex-start;
    }
}

.container {
    .section--title {
        text-transform: uppercase;
        margin-bottom: 60px;
    }
    .row .section--title {
        margin-bottom: 60px;
    }

    .row {
        margin-bottom: 40px;
    }
}
