.Page.FAQsContact {

    .section--content-nav-menu > .container {
        padding-top:0 !important;

        .content-nav-menu--items {
            position:relative;

            @include responsive ($tabletSmall, max) {
                display:flex;justify-content:center;width:max-content;
                max-width:100%;margin:0 auto;
            }

            @include responsive ($mobile, max) {
                display:flex;flex-direction:column;
            }

            .content-nav-menu--item {
                margin-bottom:24px;padding-left:20px;
                position:relative;cursor:pointer;transition:var(--default-transition);
                span {
                    font-weight:300;text-transform:uppercase;
                }
                @include responsive ($tabletSmall, max) {
                    padding-left:0px;padding-top:20px;margin-right:24px;
                }

                @include responsive ($mobile, max) {
                    display:flex;flex-direction:column;margin-right:0 !important;
                    margin-bottom:40px !important;

                    span {
                        font-size:22px;text-align:center;margin-bottom:16px;
                        pointer-events:text;
                    }
                }
                &:last-of-type {
                    margin-bottom:0px;
                    @include responsive ($tabletSmall, max) {
                        margin-right:0px;
                    }
                }
                &.--active {
                    span { font-weight:700;}
                    &:before {
                        background-color: var(--orange);
                    }
                }

                &:before {
                    content:"";position:absolute;display:block;left:-14.5px;top:0;bottom:0;margin:auto;
                    width:10px;height:10px;border:1px solid var(--orange);
                    border-radius:50px;background-color: var(--white);
                    transition:var(--default-transition);

                    @include responsive ($tabletSmall, max) {
                        top:-4.5px;bottom:auto;left:0;right:0;
                    }

                    @include responsive ($mobile, max) {
                        display:none !important;
                    }
                }

                @include responsive ($mobile) {
                    &:hover {
                        color:var(--base-70);
                        &:before {
                            border-color:var(--orange-70);
                        }
                    }
                }
            }

            &:before {
                content:"";position:absolute;display:block;
                left:-10px;top:0;bottom:0;margin:auto;height:calc(100% - 20px);width:0;
                border-left:1px var(--orange) dotted;

                @include responsive ($tabletSmall, max) {
                    width:calc(100% - 20px);height:0;top:0;bottom:auto;left:0;right:0;
                    margin:auto;
                    border-left:0;border-bottom:1px var(--orange) dotted;
                }

                @include responsive ($mobile, max) {
                    display:none !important;
                }
            }
        }
        .accordeons_container {
            .accordeon_container {
                // opacity:0;max-height:0;
                // transition:all .8s ease-in-out;
                height:auto;
                &.--active {
                    // opacity:1;max-height:max-content;
                    -webkit-animation: heightAnim 1s ease-in-out;
                    -webkit-animation-fill-mode: forwards;
                }

                height: 0px;max-height:0;
                opacity:0;
                

                @-webkit-keyframes heightAnim {
                  0% {
                    height:0px;max-height:0;
                    opacity:0;
                  }
                  100% {
                    height: 100%;max-height:100%;
                    opacity:1;
                  }
                }
            }
        }
    }

    .section--contact-form {
        .container {
            .form--wrapper {
                padding-top:20px;
                margin-bottom:20px;

                .linkedInput-for-hearAbout-segment_field_5 {
                    label {
                        margin:0 !important;padding:0 !important;
                        height:0 !important;opacity:0;visibility:hidden;
                    }
                }

                form > .wswyg--content{
                    font-size: 0.7em;
                    opacity: 0.7;
                    margin-bottom: 5em;

                    @include responsive($tabletSmall){
                        text-align: center;
                    }

                    a{
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .container {
        .section--title {
            text-transform: uppercase;
            margin-bottom:60px;
        }
        .row .section--title {
            margin-bottom:60px;
        }

        .row {
            margin-bottom:40px;
        }
    }
}