.Intro {
  @include cover(fixed);
  z-index: 2000;
  transform: translateY(-100%);
  background: var(--base);
  visibility: hidden;
  overflow: hidden;

  html.--dark & {
    background: var(--base-d);
  }

  body.--animating & {
    visibility: visible;
  }

  .inner {
    @include cover();
    @include flex(center, center);
  }
}
