.VimeoPlayer{
  aspect-ratio: 16/9;
  width: 100%;
  max-width: 1000px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &.--playing{
    > .poster{
      opacity: 0;
      pointer-events: none;
    }
  }

  &:hover{
    > .poster{
      img{
        transform: scale(1.05);
      }

      .play{
        transform: translate(-50%, -50%) scale(0.95);
      }
    }
  }

  > iframe,
  > .poster{
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
  }

  > .poster{
    z-index: 1;
    transition: opacity 500ms ease;

    > *{
      pointer-events: none;
    }
    
    img, 
    .play{
      transition: transform 500ms ease;
    }

    .play{
      @include flex(center, center);
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      min-width: 70px;
      z-index: 1;
      border-radius: 50%;
      background: var(--base);
      
      &, .icon{
        aspect-ratio: 1;
      }
      
      .icon{
        position: relative;
        min-width: 30px;
        max-width: 30px;

        svg{
          position: absolute;
          top: 0; left: 0;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
