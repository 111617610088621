.OurWorkSchema{
  @include imgRatio(1007, 1007);
  position: relative;
  width: 100%;

  &.--show-popup{
    > svg {
      transform: scale(0.95);
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.--show-popup,
  &:hover{
    > svg{
      g.arrows,
      g.compass,
      g.green-circle{
        animation-play-state: paused;
      }
    }
  }

  > svg{
    @include content();
    position: absolute;
    inset: 0;
    overflow: visible;
    transition: transform 450ms ease;

    &,
    g.green-circle,
    g.arrows,
    g.compass{
      transform-origin: 50% 50%;
      transform-box: fill-box;
      
      > *{
        cursor: pointer;
      }
    }

    g.green-circle{
      animation: spin 80s infinite reverse linear;
    }

    g.arrows{
      animation: spinArrows 120s infinite linear;

      > path{
        pointer-events: none;
      }
    }

    g.compass {
      animation: spin 10s infinite linear;
    }
    
    g.compass,
    g.paths{
      pointer-events: none;
    }

    g.tales{
      > *{
        cursor: pointer;
        pointer-events: all;
      }
    }
  }

  .popups{
    .tales,
    .arrows{
      .popup{
        border-radius: 10px;
        width: 100%;
        color: white;
        position: absolute;

        &.--active{
          transition: transform 400ms ease,
                      opacity 400ms ease;
        }

        h3{
          font: 700 20px var(--main-font);
          margin-bottom: 1em;
        }

        p{
          font: 700 14px var(--main-font);
        }

        .Link{
          font-size: 14px;
          margin-top: 2em;
          font-weight: 700;

          &:before,
          &:after{
            background: var(--white);
          }
        }

        > button{
          font-size: 10px;
          position: absolute;
          top: 0; right: 0;
          padding: 1em;
        }
      }
    }

    .tales{
      .popup{
        aspect-ratio: 1;
        padding: 5em 2em;
        max-width: 300px;
        background: rgba(#23376f, 0.98);
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);

        &:not(.--active){
          pointer-events: none;
          opacity: 0;
          transform: translate(-50%, -50%) scale(0.5);
        }
      }
    }

    .arrows{
      .popup{
        padding: 2em;
        border-radius: 10px;
        width: 100%;
        max-width: 500px;
        color: white;
        top: 100%; left: 50%;
        transform: translate(-50%, -150%);
        text-align: center;

        &:not(.--active){
          pointer-events: none;
          opacity: 0;
          transform: translate(-50%, -100%);
        }

        &.economy{
          background: #F8AD98;
        }

        &.industry{
          background: #F37D6B;
        }

        &.financial {
          background: #FF624A;
        }
      }
    }
  }

  .schema-footer{
    @include flex(flex-start, space-between);
    flex-wrap: wrap;
    margin-top: 2em;

    p{
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: #211E1F;
    }

    .legend{
      display: grid;
      grid-gap: 1em;

      > *{
        @include flex(center);
        gap: 1em;

        &:first-child{
          p{
            color: #F37D6B;
          }
        }

        &:nth-child(2){
          .legend-icon{
            transform: rotate(45deg) scale(0.8);
          }

          p{
            color: #23376F;
          }
        }

        .legend-icon{
          @include imgRatio(1, 1);
          width: get-vw(50);
          position: relative;

          @include responsive($tabletSmall, max){
            min-width: 50px;
          }

          svg{
            @include cover();
          }
        }
      }
    }

    > p{
      strong{
        font-size: 1.5em;
      }
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinArrows {
  0%{
    transform: rotate(0deg);
  }
  
  50%{
    transform: rotate(180deg); 
  }

  100% {
    transform: rotate(360deg);
  }
}
