.PageCustomPassword{
  > .page__header--container{
    .container{
      padding-top: 0 !important;
    }

    .wswyg--content, 
    .container-fullwidth {
      margin-top: 2em;
    }

    .container-fullwidth {
      min-height: 500px;
      max-height: 550px;
      height: auto;

      @include responsive($desk, max) {
        min-height: 520px;
      }
      @include responsive($tablet, max) {
        min-height: 480px;
      }
      @include responsive($tabletSmall, max) {
        min-height: 380px;
      }
      @include responsive($mobile, max) {
        min-height: 280px;
      }
      @include responsive($mobileSmall, max) {
        min-height: 200px;
      }
    }
  }

  > main{
    padding-top: 0 !important;

    .container{
      padding-top: 0 !important;
      display: grid;
      grid-gap: 2em;
      align-items: start;
      
      @include responsive($tabletSmall){
        grid-template-columns: 1.15fr 0.85fr;
      }

      @include responsive($tablet){
        grid-template-columns: 1.5fr 0.5fr;
      }
    }
  }

  section.contents{
    width: 100%;
    overflow: hidden;
    padding: 0;

    @include responsive($tabletSmall, max){
      order: 1;
    }

    h1{
      font-size: 50px;
      @include fluid-type($mobileSmall, $deskXL, 48px, 50px);
    }

    h2{
      font-size: 44px;
      @include fluid-type($mobileSmall, $deskXL, 40px, 44px);
    }

    h3{
      font-size: 30px;
      @include fluid-type($mobileSmall, $deskXL, 27px, 30px);
    }

    h4{
      font-size: 26px;
      @include fluid-type($mobileSmall, $deskXL, 24px, 26px);
    }

    h5{
      font-size: 22px;
      @include fluid-type($mobileSmall, $deskXL, 19px, 22px);
    }

    h6{
      font-size: 17px;
      @include fluid-type($mobileSmall, $deskXL, 16px, 17px);
    }

    .wswyg--content{
      > div,
      .wp-caption,
      img{
        width: auto !important;
        max-width: 100% !important;
      }
    }
  }

  .section-content-picture{
    @include flex(flex-start);
    flex-wrap: wrap;
    gap: 2em;
    padding: 2em 0;
    overflow: hidden;

    @include responsive($tablet, max){
      flex-direction: column;
    }

    @include responsive($tablet){
      justify-content: space-between;
    }

    &[data-layout-disposition="image_left"],
    &[data-layout-disposition="image_right"] {
      > * {
        @include responsive($tablet){
          flex: 1 0 calc(50% - 2em);
          max-width: calc(50% - 2em);
        }
      }
    } 

    &[data-layout-disposition="image_left"] {
      .visual__container{
        order: 0;
      }

      .wswyg--content{
        order: 1;
      }
    }

    &[data-layout-disposition="image_right"] {
      .visual__container{
        order: 1;
      }

      .wswyg--content{
        order: 0;
      }
    } 

    &[data-layout-disposition="image_full_top"],
    &[data-layout-disposition="image_full_bottom"] {
      > * {
        @include responsive($tablet){
          flex: 1 0 100%;
          max-width: 100%;
        }
      }
    } 

    &[data-layout-disposition="image_full_top"] {}

    &[data-layout-disposition="image_full_bottom"] {
      .wswyg--content{
        order: 0;
      }

      .visual__container{
        order: 1;
      }
    } 

    .visual__container{
      // @include imgRatio(1, 1);

      .visual{
        position: relative;
      }
    }

    .swiper{
      flex: 1 0 100%;
      max-width: 100%;
      order: 3;

      .swiper-slide{
        position: relative;
        width: auto;
        max-width: 100%;
        height: auto;

        @include responsive($tablet){
          max-width: 80%;
        }

        > *{
          .visual{
            transform: none;
          }
        }
      }

      .swiper-navigation{
        position: relative;
        inset: 0;
        order: 0;
        justify-content: flex-end;
        margin-bottom: 1.5em;

        .prev{
          transform: rotate(-180deg);
        }
      }
    }
  }

  aside{
    @include flex(flex-start);
    flex-direction: column;
    gap: 2em;

    @include responsive($tabletSmall, max){
      order: 0;
    }

    @include responsive($tabletSmall){
      position: sticky;
      top: 1em;
    }

    > * {
      width: 100%;
    }

    #TOC-opener{
      @include flex(center, center);
      flex-wrap: wrap;
      background-color: var(--base);
      color: var(--white) !important;
      gap: 1em;

      @include responsive($tabletSmall){
        display: none;
      }

      &:after{
        content: '';
        display: inline-block;
        height: 24px;
        width: 24px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' width='24' height='24' stroke-width='2'%3E %3Cpath d='M6 9l6 6l6 -6'%3E%3C/path%3E %3C/svg%3E");
        background-size: auto;
        background-repeat: no-repeat;
      }
    }

    nav {
      outline: 1px solid rgba(black, 0.2);
      padding: 2em;

      @include responsive($tabletSmall) {
        padding: 1em;
      }

      &:not(.--active) {
        @include responsive($tabletSmall, max){
          display: none;
        }
      }

      > span{
        font-weight: 700;
        font-size: 16px;
        display: block;
        border-bottom: 1px solid rgba(black, 0.2);
        margin-bottom: 1em;
        padding-bottom: 1em;
      }

      > ul{
        @include flex(flex-start);
        flex-direction: column;
        gap: 0.75em;

        @include responsive($tabletSmall) {
          gap: 0.25em;
        }

        li{
          a{
            display: inline-block;
            font-size: 14px;
            line-height: 1.2;
            opacity: 0.5;
            transition: opacity 400ms ease;

            @include responsive($tabletSmall) {
              max-width: 250px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            &.--active{
              opacity: 1;
              text-decoration: underline;
            }

            // &.toc-level-h3{
            //   font-size: 0.95em;
            // }

            // &.toc-level-h4{
            //   font-size: 0.9em;
            // }

            // &.toc-level-h5{
            //   font-size: 0.85em;
            // }

            // &.toc-level-h6{
            //   font-size: 0.8em;
            // }
          }
        }
      }
    }

    .soundcloud{
      > *{
        width: 100%;
      }

      > .soundcloud_desktop{
        @include responsive($tabletSmall, max){
          display: none;
        }
      }

      > .soundcloud_mobile{
        @include responsive($tabletSmall){
          display: none;
        }
      }
    }
  }
}
