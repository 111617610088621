.posts-list--items {
	display:flex;flex-wrap:nowrap;
	justify-content:center;
	margin-bottom:25px;

	@include responsive($tablet, max) {
		flex-wrap:wrap;
	}
	@include responsive($mobile, max) {
		flex-direction:column;
		align-items:center;
	}

	.posts-list--item {
		display:flex;flex-direction:column;width:calc((100% / 3) - 20px);

		width:calc((100% / 3) - 20px);margin-right:30px;margin-bottom:30px;align-items:center;

		@include responsive($tablet, max) {
			width:calc(50% - 30px);margin-right:30px;
		}

		@include responsive($tabletSmall, max) {
			width:calc(50% - 10px);margin-right:20px;
		}
		@include responsive($mobile, max) {
			margin-right:0 !important;width:100%;max-width:380px;
		}

		&:last-of-type {
			margin-right:0 !important;
		}
		&:nth-child(2n) {
			@include responsive($tablet, max) {
				margin-right:0 !important;
			}
		}

		
		.posts-list-item__img a {
			@include noTouchHover() {
				opacity: 0.5;

				img {
					transform: scale(1.05);
				}
			}
		}

		.posts-list-item__img {
			background: var(--base);
			overflow: hidden;
			// margin-bottom:20px;
			width: 100%;
			a {
				transition: opacity 420ms $ease;

				img {
					height: 235px;
					width: 100%;
					object-fit: cover;
					transition: transform 420ms $ease, opacity 400ms linear 400ms;
				}
			}
		}

		.posts-list--content {
			padding:20px 5px;

			.posts-list--item__text {
				margin-bottom:20px;

				a {
					transition:var(--default-transition);
					text-decoration: none;
					&:hover {
						color:var(--base-70);
					}
				}

				h3 {
					font-size:27px;
					line-height:1.1em;
					@include fluid-type($mobileSmall, $deskXL, 16px, 27px);
					margin-bottom:16px;
					text-transform: uppercase;
				}
			}

			.posts-list--item__footer {
				.posts-list--item__cat {
					margin-right:12px;
					color:var(--orange);
					font-weight:bold;
					font-size:11px;line-height:1.2em;
					border:1px solid var(--orange);
					border-radius:29px;
					text-transform: uppercase;
					padding:6px 12px;
				}

				.posts-list--item__date {
					color:var(--blue-60);
					font-size:12px;line-height:1.2em;
					font-family:"le-monde-livre-std", serif;font-weight:400;font-style:italic;
				}
			}
		}
	}
}