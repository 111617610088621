.Page.AboutUs {
	.container-fullwidth {
		min-height: 665px;
		height: auto;

		@include responsive($desk, max) {
			min-height: 520px;
		}
		@include responsive($tablet, max) {
			min-height: 480px;
		}
		@include responsive($tabletSmall, max) {
			min-height: 380px;
		}
		@include responsive($mobile, max) {
			min-height: 280px;
		}
		@include responsive($mobileSmall, max) {
			min-height: 200px;
		}
	}

	.section--swiper__quotes {
		.container {
			padding-top: 70px !important;
			@include responsive($tablet, max) {
				padding-top: 50px !important;
			}
		}
	}

	.section--content.--grey:not(.section--rows-of-content) {
		.container {
			padding-bottom: 80px;

			.row {
				margin-bottom: 0 !important;
				> .w50per .section--title {
					margin-bottom: 100px;
					@include responsive($tabletSmall, max) {
						margin-bottom: 40px;
					}
				}
			}
		}
	}

	.section--content.--presentation{
		.--picture-left{
			.wrapper{
				@include responsive($tabletSmall){
					max-width: 80%;
				}
			}

			.visual__container{
				@include imgRatio(922, 1000);
			}

			p{
				margin-top: 1em;
				font-size: 0.8em;
				text-align: right;
				margin-left: auto;
			}
		}
	}
}

.section--numbers .col {
	padding: 0 40px;
	position: relative;
	&:after {
		content: "";
		background-color: var(--white);
		opacity: 0.1;
		position: absolute;
		left: auto;
		right: 0;
		display: block;
		top: 0;
		bottom: 0;
		height: 100%;
		width: 1px;
		@include responsive($tabletSmall, max) {
			height: 1px;
			width: 100%;
			top: auto;
			bottom: -30px;
			left: 0;
			right: 0;
		}
	}
	&:last-of-type:after {
		display: none;
	}
	.numbers--title {
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		font-size: 90px;
		@include fluid-type($mobileSmall, $deskXL, 58px, 90px);
	}
	.numbers--text {
		color: var(--white-70);
	}
}

.section--committee {
	.container {
		padding-top: 0 !important;
		padding-bottom: 0 !important;

		.toggle-committee--menu {
			display: flex;
			margin-bottom: 30px;
			padding: 5px;
			border-radius: 50px;
			border: 1px var(--blue-20) solid;
			max-width: 100%;
			width: max-content;
			position: relative;
			z-index: 2;

			@include responsive($mobile, max) {
				flex-direction: column;
				border: 0;
				align-items: center;
				width: 100%;
			}
			.toggle-committee--menu_btn {
				background-color: transparent;
				padding: 18px 20px;
				border-radius: 50px;
				color: var(--base);
				font-weight: 700;
				font-size: 13px;
				text-transform: uppercase;
				transition: var(--default-transition);
				cursor: pointer;
				transition: background 420ms $ease 0ms, color 240ms linear 0ms;
				&.--active {
					background-color: var(--base);
					color: var(--white) !important;
				}
				&:hover {
					color: var(--blue-20);
				}

				@include responsive($mobile, max) {
					border: 1px var(--base) solid;
					margin-bottom: 16px;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
		.toggle-committee--content {
			.toggle-committee--tab {
				height: auto;
				position: relative;
				transition: transform 420ms $ease 0ms, opacity 240ms linear 0ms;
				&.--active {
					visibility: visible;
					opacity: 1;
					max-height: unset;
					transform: translateY(0);
					transition-delay: 240ms;
				}

				&:not(.--active) {
					max-height: 0;
					visibility: hidden;
					opacity: 0;
					transform: translateY(20px);
					transition-delay: 0ms;
				}

				&#committee-operation{
					.committee--items{
						@include responsive($tablet){
							grid-template-columns: repeat(4, 1fr);
						}
					}
				}

				.committee--items {
					display: grid;
					grid-gap: 1em;

					&.align-start{
						align-items: start;
					}

					@include responsive($mobile){
						grid-template-columns: repeat(2, 1fr);
					}

					@include responsive($tablet){
						grid-template-columns: repeat(3, 1fr);
					}
				}
			}
		}
	}
}

.section--team {
	.container .col.--w-100{
		width: 100%;
	}

	.container.team-list--container {
		padding-top: 0 !important;

		.team-list--items {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.teams-list--item {
				display: flex;
				flex-direction: column;
				padding: 20px;
				flex: 1 0 100%;
				
				@include responsive($tabletSmall){
					flex: 1 0 33.33333%;
					max-width: 215px;
				}

				@include responsive($tablet){
					flex: 1 0 80%;
					max-width: 265px;
				}

				.teams-list--item__img {
					@include flex(center, center);
					height: get-vw(180);
					width: get-vw(180);
					border-radius: 50%;
					position: relative;
					margin-bottom: 20px;
					overflow: hidden;
					max-width: 120px;
					max-height: 120px;

					@include responsive($tabletSmall, max){
						min-width: 80px;
						min-height: 80px;
					}

					&.--empty{
						background: #f3f3f3;
					}

					&:not(.--empty) {
						img{
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}
				}

				.teams-list--item__content {
					display: flex;
					flex-direction: column;

					strong {
						font-size: 17px;
					}

					div {
						font-size: 15px;
					}

					.teams-list--item__excerpt {
						color: var(--blue-50);
					}
				}
			}
		}
	}
}

.container {
	.section--title {
		text-transform: uppercase;
		margin-bottom: 60px;
	}
	.row .section--title {
		margin-bottom: 60px;
	}

	.row {
		margin-bottom: 40px;
	}
}
