*[data-inview] {

  &.--visible > * {
    @for $i from 1 to 10 {
      &:nth-child(#{$i}) { transition: transform 1600ms $easeOutExpo $i * 0.05s, opacity 240ms linear $i * 0.05s; }
    }
  }

  &:not(.--visible) > * {
    transform: translateY(40px);
    opacity: 0;
  }
}