.Search {
  @include flex();
  margin-left: 1em;
  pointer-events: all;

  > button {
    padding: 1em;

    .Icon.--search {
      svg {
        stroke: var(--base);

        .homepage & {
          stroke: var(--white);
        }
      }
    }
  }

  > .popup {
    @include cover(fixed);
    z-index: 100;
    // background: var(--base);
    // background: var(--white);
    background: var(--grey);
    overflow-y: scroll;
    transform: translate(0, -100%);

    .close-search {
      @include flex(center, center);
      height: 50px;
      width: 50px;
      position: fixed;
      top: 5%;
      right: 5%;
      z-index: 10;
      // background: var(--white);
      background: var(--base);
      border-radius: 50%;
      transition: transform 500ms $ease;

      @include noTouchHover() {
        transform: scale(0.8) rotate(-90deg);
      }

      &:before,
      &:after {
        @include content();
        position: absolute;
        // background: var(--base);
        // background: var(--white);
        background: var(--grey);
        width: 12px;
        height: 2px;
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }

    .container {
      padding-top: 25dvh;

      > .wrapper {
        @include flex(center, center);
        flex-direction: column;
        width: 100%;
        padding-bottom: 10em;

        @include responsive($tabletSmall) {
          max-width: 75%;
          margin: 0 auto;
        }

        .search-bar {
          width: 100%;
          // color: var(--white);
          color: var(--base);

          // Input container with icon
          &__input {
            position: relative;

            .Icon.--search {
              position: absolute;
              z-index: 2;
              top: 16px;
              left: 20px;
              width: 20px;

              @include responsive($tabletSmall) {
                width: 25px;
                top: 25px;
                left: 30px;
              }

              svg{
                stroke: var(--base);
              }
            }

            input {
              font: 400 20px var(--main-font);
              // color: var(--white);
              color: var(--base);
              // border: 1px solid rgba(white, 0.7);
              border: 1px solid rgba(#002060, 0.7);
              border-radius: 20em;
              padding: 0.5em 1em 0.75em 2.5em;
              width: 100%;
              position: relative;
              z-index: 1;
              background: var(--grey);

              &[type='search']::-webkit-search-decoration,
              &[type='search']::-webkit-search-cancel-button,
              &[type='search']::-webkit-search-results-button,
              &[type='search']::-webkit-search-results-decoration {
                display: none;
              }

              &::placeholder{
                color: var(--base);
                opacity: 0.5;
              }

              @include responsive($tabletSmall) {
                font-size: 28px;
              }
            }

            .examples {
              position: absolute;
              top: 80px;
              left: 5%;
              filter: opacity(0.5);
              z-index: -1;

              @include responsive($tabletSmall, max) {
                top: 70px;
              }
            }
          }

          // Autocomplete box (auto generated)
          .autoComplete_wrapper {
            > #autoComplete_list_1 {
              position: relative;
              z-index: 1;
              padding: 1.5em;
              background: #001847;
              border-radius: 10px;
              color: var(--white);
              border-radius: 0 0 10px 10px;
              font-size: 20px;
              color: var(--white);
              max-width: 95%;
              margin: 0 auto;
              margin-top: -1em;
              z-index: 0;

              > *{
                cursor: pointer;
              }
            }
          }
        }

        #results {
          margin-top: 3.5em;
          // color: var(--white);
          color: var(--base);
        }

        a {
          // color: var(--white);
          color: var(--base);
        }
      }

      #search-see-more{

      }
    }

    .Btn#search-see-more {
      margin-top: 3.5em;
    }
  }
}
