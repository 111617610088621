$menuBreakpoint: $tablet;

body:not(.homepage) {
  .Header {
    position: absolute;
    top: 0;
  }
  main {
    @include responsive($tabletSmall) {
      padding-top: 128px;
    }
    @include responsive($tabletSmall, max) {
      padding-top: 108px;
    }
  }
}

.Header {
  left: 0;
  width: 100%;
  z-index: 120;
  pointer-events: none;

  > .container {
    width: 100%;
    max-width: none;
    @include flex(center, flex-end);
    padding-top: 30px;
    padding-bottom: 30px;

    @include responsive($tabletSmall) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .logo:not(.logo-dark) {
      display: none;
    }

    .language-toggle {
      display: inline-block;
      margin-left: 2em;
      font-weight: 700;
      pointer-events: all;
      position: relative;
      a {
        transition: var(--default-transition);
        opacity: 0.5;
        position: relative;
        font-size: 13px;
        line-height: 1.3;
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 100%;
          transform: scaleX(0);
          transform-origin: left center;
          background-color: var(--base);
          transition: transform 400ms $easeOutQuad;
        }
        &.--active,
        &:hover {
          opacity: 1;
        }
        &:hover:not(.--active):after {
          transform: scaleX(100%);
        }
      }
      @include responsive($tablet, max) {
        margin-left: 0;
        margin-right: 2em;
      }
    }
  }

  .languages{
    @include flex();
    padding: 0.5em 0.75em;
    pointer-events: all;
    
    @include responsive($menuBreakpoint, max){
      margin-left: 1em;
    }

    @include responsive($menuBreakpoint){
      position: absolute;
      top: 0; right: 0;
      z-index: 10;
    }

    > li{
      display: inline-flex;
      align-items: center;

      &:not(.--active){
        > .languageLink{
          opacity: 0.5;
        }
      }

      &:not(:last-child){
        &:after{
          content: '/';
          display: inline-block;
          font-size: 11px;
          margin: 0 0.5em;
          
          .homepage &{
            color: var(--white);
          }
        }
      }

      .languageLink{
        margin: 0;
        font-size: 12px;

        body.showMenu &,
        .homepage &{
          color: var(--white);

          &:after{
            background: var(--white);
          }
        }
      }
    }
  }
}
