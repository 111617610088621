#Outdated {
  @include flex(center, center);
  @include cover(fixed);
  z-index: 2400;
  background: var(--base);

  &:not(.show) {
    display: none;
  }

  .inner {
    color: var(--white);
    text-align: center;
  }

  h4 {
    margin-bottom: 0px;
  }

  .Btn {
    background: var(--white);

    .Btn__txt {
      color: var(--base);
    }
  }
}
