.Page.ContentLibrary {
	.section--list-content {
		&.--loading > .container {
			cursor: progress;
			opacity: 0.5;

			> *,
			a {
				pointer-events: none !important;
			}
		}

		.container {
			padding-top: 0 !important;
			transition: opacity 240ms linear;

			.row--posts-list--menu {
				.posts-list--menu {
					display: flex;
					width: 100%;
					border: 1px solid var(--blue-20);
					padding: 10px;
					border-radius: 2em;
					justify-content: center;
					
					@include responsive($tabletSmall, max){
						flex-wrap: wrap;
						gap: 0;
					}
					
					@include responsive($tabletSmall){
						height: 70px;
						border-radius: 50px;
					}

					.posts-list--menu-item {
						min-width: calc(100% / 3);
						text-align: center;
						height: 50px;
						border-radius: 50px;
						cursor: pointer;
						transition: var(--default-transition);
						
						@include responsive($tabletSmall){
							flex-grow: 1;
						}

						> span {
							color: var(--base);
							font-size: 13px;
							font-weight: 700;
							line-height: 13px;
							padding: 18px 30px;
							text-transform: uppercase;
							transition: var(--default-transition);
							white-space: nowrap;

							@include responsive($mobile, max) {
								font-size: 11px;
							}

							@include responsive($tablet, max) {
								padding: 18px 20px;
							}
						}

						&:hover {
							background-color: var(--blue-20);
						}

						&.--active {
							background-color: var(--base);
							span {
								color: var(--white);
							}
						}
					}
				}
			}

			.posts--wrapper {
				padding-top: 0 !important;
				margin-bottom: 0 !important;

				> * {
					position: absolute;
					width: 100%;
					pointer-events: none;
					opacity: 0;

					&.--active {
						position: relative;
						pointer-events: all;
						opacity: 1;
					}
				}

				@include responsive($tablet, max) {
					flex-direction: column;
				}

				.posts-list--items--mobileTitle {
					@include responsive($tablet) {
						display: none !important;
					}
					margin-bottom: 30px;
					display: block;
					text-align: center;
					strong {
						text-transform: uppercase;
						font-size: 22px;
						line-height: 1.2;
						font-weight: 700;
					}
				}
				.posts-list--items-wrapper {
					@include responsive($tablet) {
						display: none;
						&.--active {
							display: flex;
							flex-direction: column;
							align-items: center;
							width: 100%;

							> .posts-list--items {
								display: grid;
								grid-gap: 40px;
								width: 100%;
								
								@include responsive($mobile){
									grid-template-columns: repeat(2, 1fr);
								}

								@include responsive($tablet){
									grid-template-columns: repeat(6, 1fr);
								}


								&.--odd{}

								&.--even{
									> .posts-list--item{
										&:first-child,
										&:nth-child(2) {
											@include responsive($tablet){
												grid-column: span 3;
											}

											// @include responsive($tablet){
											// 	width: calc(50% - 20px);
											// }
										}
									}
								}

								> .posts-list--item{
									margin: 0;
									width: 100%;

									@include responsive($mobile){
										grid-column: span 2;
									}
								}
							}
						}
						> a {
							margin-top: 46px;
						}
					}

					// .posts-list--item {
					// 	display:flex;flex-direction:column;
					// 	padding:20px;width:255px;

					// 	.posts-list--item__img {
					// 		margin-bottom:20px;
					// 	}
					// 	.posts-list--item__content {
					// 		display:flex;flex-direction:column;

					// 		strong {
					// 			font-size:17px;line-height:22px;
					// 		}
					// 		div {
					// 			font-size:15px;line-height: 19.5px;
					// 		}
					// 		.posts-list--item__description2 {
					// 			color:var(--blue-50);
					// 		}
					// 	}
					// }

					@include responsive($tablet, max) {
						margin-bottom: 70px;
						display: flex;
						flex-direction: column;
						align-items: center;
					}
				}
			}
		}
	}
}

.container {
	.section--title {
		text-transform: uppercase;
		margin-bottom: 60px;
	}
	.row .section--title {
		margin-bottom: 60px;
	}

	.row {
		margin-bottom: 40px;
	}
}
