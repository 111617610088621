.Page.OurWorkChild {

	.page__header--container {
		position: relative;
		
		&.--bg {
			background-size:cover;background-repeat:no-repeat;background-position:center;

			.container, .container a {
				color:var(--white);
			}

			.container{
				position: relative;
				z-index: 1;
			}

			&:after{
				@include content();
				@include cover();
				background: rgba(0, 0, 0, 0.5);
			}
		}

		.container {
			padding-top:120px;padding-bottom:120px;
		}
	}

	.section--content {
		.row-vertical {
			.col--player {
				margin:0 auto 78px auto;width:100%;
				display:block !important;
				@include responsive ($tabletSmall, max) {
					margin-bottom:48px;
				}
			}

			> .w100.col > .section--title {
				margin-bottom:70px;
			}
		}
	}

	.section--actions {
		h2.section--title{
			@include fluid-type($mobileSmall, $deskXL, 26px, 32px);
			// font-size: 26px;
		}

		.wswyg--content{
			margin-top: 2em;
		}
	}

	.section--tools-and-ressources {
		.container {
			padding-top:120px;padding-bottom:120px;
			@include responsive($tablet, max) {
				padding-top:80px;padding-bottom:80px;
			}
			@include responsive($mobile, max) {
				padding-top:40px;padding-bottom:40px;
			}
			& > .row:first-of-type {
				margin-bottom:80px;
				@include responsive ($tabletSmall, max) {
					margin-bottom:60px;
				}
				@include responsive ($mobile, max) {
					margin-bottom:50px;
				}
			}
			& > .row:last-of-type {
				margin-bottom:0px;
			}
		}
	}

	.section--text.--blue{
		.wswyg--content a:not(.Btn){
			color: var(--white);
		}
	}

	
}

	.container {
		.section--title {
			text-transform: uppercase;
			margin-bottom:60px;
		}
		.row .section--title {
			margin-bottom:60px;
		}

		.row {
			margin-bottom:40px;
		}
	}