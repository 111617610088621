.Icon {
  position: relative;
  @include imgRatio(1, 1);
  min-width: 24px;

  svg {
    @include cover();
    fill: var(--base);
  }

  &[data-name="play"],
  &.--play {
    @include imgRatio(17, 20);
    min-width: 17px;
  }

  &.--arrow{
    @include imgRatio(24, 24);
    width: get-vw(24);

    @include responsive($tabletSmall, max){
      min-width: 24px;
    }

    svg > *{
      stroke: var(--base);
    }
  }

  &.--search{
    width: 25px;

    @include responsive($tabletSmall, max){
      width: 20px;
    }

    svg{
      fill: none;
      stroke: var(--white);
    }
  }
}
