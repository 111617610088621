.App {
  width: 100%;
  min-height: 100vh;
  background: var(--white);
  position: relative;
  z-index: 10;

  html:not(.no-js) body.--loading & {
    pointer-events: none;
  }

  html.--dark & {
    background: var(--base);
    color: var(--white);
  }

  > * {
    width: 100%;

    & + * {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.container {
  width: 100%;
  max-width:1440px;
  padding: 0 30px;
  position: relative;
  margin: 0 auto;

  @include responsive($tabletSmall) {
    padding: 0 calc(100vw / 24);
  }
  @include responsive($deskXL) {
    padding: 0 80px;
  }
}

section {
  padding: 80px 0;

  @include responsive($tabletSmall) {
    padding: get-vw(100) 0;
  }
}

.sticky {
  position: sticky;
  top: 80px;
}