.Nav {
  &__container {
    pointer-events: all;

    .--animating & {
      pointer-events: none;
    }

    @include responsive($menuBreakpoint, max) {
      @include flex(center, center);
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--base);
      color: var(--white);
      overflow-y: auto;
    }

    body:not(.showMenu) & {
      @include responsive($menuBreakpoint, max) {
        display: none;
      }
    }
  }

  &__main {
    list-style-type: none;
    margin: 0;
    padding: 0;

    @include responsive($menuBreakpoint, max) {
      padding: 25dvh 40px;
      width: 100%;
    }
  }

  &__item {
    text-align: center;
    position: relative;

    @include responsive($menuBreakpoint) {
      display: inline-block;
    }

    & + & {
      @include responsive($menuBreakpoint) {
        margin-left: 2em;
      }
    }

    &.--submenu {
      @include responsive($menuBreakpoint) {
        @include noTouchHover() {
          > .Nav__submenu {
            opacity: 1;
            transform: translate(-50%, 0%);
            pointer-events: all;
          }
        }
      }
    }
  }

  &__submenu {
    @include responsive($menuBreakpoint) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 5%);
      z-index: 0;
      opacity: 0;
      padding-top: 2.5em;
      min-width: 15em;
      pointer-events: none;
      transition:
        opacity 350ms var(--ease-in-out-circ),
        transform 350ms var(--ease-in-out-circ);
    }

    > ul {
      @include flex(flex-start);
      flex-direction: column;
      gap: 0.5em;
      padding: 1.5em;
      position: relative;

      @include responsive($menuBreakpoint, max){
        align-items: center;
        justify-content: center;
      }

      @include responsive($menuBreakpoint) {
        background: #efefef;
      }

      > svg{
        position: absolute;
        bottom: 99%; left: 50%;
        transform: translate(-50%, 0);

        @include responsive($menuBreakpoint, max){
          display: none;          
        }
      }

      .Nav__link {
        @include responsive($menuBreakpoint, max) {
          font-size: 15px;
          color: var(--white);
          opacity: 0.5;
        }

        @include responsive($menuBreakpoint) {
          display: inline-block;
          text-align: left;
          color: var(--base);
        }

        &:after {
          content: none;
        }

        @include noTouchHover() {
          > span {
            background-size: 100% 1px;
            background-position: 0% 100%;
          }
        }

        span {
          display: inline;
          background-position: 100% 100%;
          background-repeat: no-repeat;
          background-size: 0% 1px;
          transition: background-size 600ms var(--ease-in-out-circ);

          @include responsive($menuBreakpoint, max) {
            background-image: linear-gradient(var(--white), var(--white));
          }

          @include responsive($menuBreakpoint) {
            background-image: linear-gradient(var(--base), var(--base));
          }
        }
      }
    }
  }

  &__link {
    position: relative;
    z-index: 1;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 1.3;
    // filter: opacity(0.3);
    // transition: filter 400ms $easeOutQuad;

    @include responsive($menuBreakpoint, max) {
      font-size: 32px;
      display: inline-block;
      margin-bottom: 10px;
    }

    &.--active,
    &:focus,
    &:hover {
      // filter: opacity(1);

      &:after {
        transform: scaleX(1);
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--base);
      transform: scaleX(0);
      transform-origin: left center;
      transition: transform 400ms $easeOutQuad;

      html.--dark & {
        background: var(--white);
      }

      body.showMenu & {
        @include responsive($menuBreakpoint, max) {
          background: var(--white);
        }
      }
    }
  }
}
