.PageMedias {
  // Page Header
  .page__header--container{
    .container > .wswyg--content{
      margin-top: 1.5em;
      width: 100%;
      
      @include responsive($tabletSmall){
        max-width: 65%;
      }
    }
  }

  section + section{
    padding-top: 0;
  }

  // Main content
  .main-content{

  }

  // Additional content
  .additional-content{

  }
}
