.Form {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;

  * {
    letter-spacing: 0 !important;
  }

  .--error {
    color: var(--error-color) !important;
  }

  &__legend {
    color: var(--blue-60);
    font-size: 15px;
    line-height: 1.3;
    font-weight: 300;
  }

  .Form__output .--error {
    border: 1px var(--error-color) solid;
    padding: 8px 12px;
    background-color: var(--error-color-20);
  }

  &__section.last {
    margin-bottom: 0 !important;
  }

  .Form-row {
    @include flex(flex-start, flex-start);

    @include responsive($tablet) {
      margin-right: -60px;
    }
    @include responsive($tablet, max) {
      margin-right: -30px;
    }
    @include responsive($mobile, max) {
      flex-direction: column;
      margin-right: 0 !important;
    }

    > * {
      width: 100%;
      margin-bottom: 24px;

      @include responsive($tablet) {
        max-width: calc(50% - 60px);
        margin-right: 60px;
      }
      @include responsive($tablet, max) {
        max-width: calc(50% - 30px);
        margin-right: 30px;
      }
      @include responsive($mobile, max) {
        max-width: 100%;
        margin-right: 0px;
      }

      &.--full {
        @include responsive($tablet) {
          max-width: calc(100% - 60px);
          min-width: calc(100% - 60px);
          margin-right: 60px;
        }
        @include responsive($tablet, max) {
          max-width: calc(100% - 30px);
          min-width: calc(100% - 30px);
          margin-right: 30px;
        }
        @include responsive($mobile, max) {
          max-width: 100%;
          min-width: 100%;
          margin-right: 0px;
        }
      }
    }

    &.privacy-field__row > div {
      margin-bottom: 0px;
    }
  }

  label:not(.Checkbox) {
    display: block;
    // text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.1em;
    // background: var(--white);
    margin-bottom: 10px;
    padding: 0;
  }

  &__group {
    position: relative;
    width: 100%;

    &:not(.--textarea):not(.--select) {
      label {
        // position: absolute;
        // top: 21px;
        // left: 10px;
        // opacity: 0.3;
        // transform: translateY(-50%) scale(1);
        // transition: all 400ms $easeOutExpo;
        // transform-origin: left center;
        // padding: 0px 10px;
      }

      &.--focused,
      &.--filled {
        label {
          // position: absolute;
          // left: 10px;
          // opacity: 1;
          // transform: translateY(-26px) scale(0.8);
        }
      }
    }

    span.--error {
      position: absolute;
      top: 100%;
      left: 0;
      font-size: 10px;
      padding-top: 2px;
    }
  }

  &__title {
    font-weight: 700;
    margin-bottom: 22px;
    text-transform: uppercase;
    letter-spacing: 0 !important;
  }

  &__control {
    width: 100%;
    font-weight: 300;
    letter-spacing: 0;
    min-height: 60px;
    padding: 15px 32px;
    outline: 0;
    border: 1px solid rgba(0, 32, 96, 0.2);
    box-shadow: none;
    background-color: var(--white);
    border-radius: 10px;

    .--error & {
      border-color: var(--error-color);
    }

    &::placeholder {
      // opacity: 0;
      color: var(--blue-40);
    }
  }

  &__section {
    margin-bottom: 40px;
  }

  select.Form__control {
    line-height: 28px;
    background-color: var(--white);
    max-width: 100%;
    position: relative;
    background-image: url("/wp-content/themes/thefashionpact/src/imgs/SVG/arrow_bottom.svg");
    background-position: calc(100% - 17px) center;
    background-size: 13px 8px;
    background-repeat: no-repeat;
  }

  textarea.Form__control {
    max-width: 100%;
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    padding: 30px;
  }

  &__actions {
    text-align: right;
    @include responsive($mobile, max) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .Btn {
      margin-top: 0;
      span {
        letter-spacing: 0 !important;
        font-size: 13px;
      }
    }
  }

  &__output {
    color: var(--valid-color);

    p:not(:empty) {
      margin-top: 20px;
    }
  }

  .errorMessage{
    color: var(--error-color);
    margin: 0.5em 0 1em;
  }
}

.Radios,
.Checkboxs {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  margin-bottom: -10px;

  @include responsive($tabletSmall) {
    @include flex(center, flex-start, inline-flex);
  }

  > * {
    margin-bottom: 10px;
    @include responsive($tabletSmall) {
      margin-right: 40px;
    }
  }

  label {
    margin-bottom: 15px;
  }
}

.Checkbox {
  position: relative;
  @include flex(flex-start, flex-start, inline-flex);
  display: flex !important;
  flex-wrap: nowrap;

  input {
    @include cover();
    margin: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
    min-width: 24px;
    max-width: 24px;
    height: 24px;

    &:checked {
      & + .Checkbox__custom {
        background: var(--base);

        &:after {
          transform: scale(1);
        }

        svg {
          opacity: 1;
        }
      }
    }
  }

  &__custom {
    @include flex(center, center);
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    border: 1px solid var(--base);
    border-radius: 2px;
    margin-right: 14px;
    transition: background 240ms $easeOutQuad;

    .--radio & {
      border-radius: 18px;

      &:after {
        @include content;
        width: 10px;
        height: 10px;
        background: var(--white);
        border-radius: 20px;
        transform: scale(0);
        transition: transform 240ms $easeOutQuad;
      }
    }

    .check {
      position: relative;
      width: 66%;

      &:before {
        @include content();
        padding-top: percentage(math.div(25, 33));
      }

      svg {
        overflow: visible;
        @include cover();
        opacity: 0;

        > * {
          fill: transparent;
          stroke: var(--white);
          stroke-width: 6px;
          stroke-linecap: round;
        }
      }
    }
  }

  &__label {
    text-transform: none;
    // font-size: 13px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    font-weight: 300;
  }
}

.conditional {
  opacity: 0.4;
  transition: var(--default-transition);
  pointer-events: none;
  // height:auto;max-height:0;visibility:hidden;transform:translateY(-100%);

  &.--active {
    opacity: 1;
    pointer-events: all;
    // visibility:visible;max-height:unset;transform:translateY(0);
  }
}

/**
* PROTECTED PAGES BY PASSWORD
*/

.post-password-form{
  @include flex(center, center);
  flex-direction: column;
  gap: 30px;
  padding: 50px;
  background-color: var(--white);
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  outline: 1px solid rgba(0, 32, 96, 0.1);
  border-radius: 10px;

  @include responsive($mobile){
    max-width: 450px;
  }

  label, input{
    display: block;
  }
  
  p:first-child{
    text-align: center;
  }
  
  p:nth-child(2){
    display: grid;
  }

  input:not([type="submit"]){
    font: large Verdana,sans-serif;
    letter-spacing: 1px;
    font-size: 20px;

    width: 100%;
    font-weight: 300;
    letter-spacing: 0;
    min-height: 60px;
    padding: 15px 32px;
    outline: 0;
    border: 1px solid rgba(0, 32, 96, 0.2);
    box-shadow: none;
    background-color: var(--white);
    border-radius: 10px 10px 0 0;

    .--error & {
      border-color: var(--error-color);
    }

    &::placeholder {
      color: var(--blue-40);
    }
  }

  input[type="submit"]{
    font-size: 18px;
    border-top: transparent;
    border-radius: 0 0 10px 10px;
    background-color: var(--base);
    color: var(--white) !important;
  }
}
