.committee--item {
  background-color: var(--white);
  padding: 20px;

  &.show-description{
    .description{
      height: auto;
    }
  }

  .inner{
    @include flex(center);
    flex-wrap: nowrap;

    .visual__container{
      @include imgRatio(500, 500);
      max-width: 100px;
      margin-right: 1em;
      border-radius: 50%;
      background: #f5f5f5;
    
      .toggle-committee--tab#committee-steering &{
        max-width: 120px;
     }
    }

    .committee--item__text {
      @include flex(flex-start);
      flex-direction: column;

      strong {
        margin-bottom: 5px;
      }

      span {
        font-size: 15px;
      }

      button{
        cursor: pointer;
        line-height: 1.25;
        border-bottom: 1px solid var(--base);
        display: inline-block;
      }
    }
  }

  .description{
    max-height: 0;
    overflow: hidden;
    transition: max-height 550ms $ease;

    .wswyg--content{
      font-size: 14px;
      padding: 1em 0;
    }
  }
}
