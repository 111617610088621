.Post {
	.breadcrumb-block{
		margin-bottom: 2em;
	}

	h1 {
		font-size: 40px;
		line-height: 1.35;

		@include responsive($tabletSmall) {
			@include font-vw(60);
		}

		@include responsive($deskXXL) {
			font-size: 60px;
		}
	}

	.container-fullwidth {
		min-height: 665px;
		height: auto;

		@include responsive($desk, max) {
			min-height: 520px;
		}
		@include responsive($tablet, max) {
			min-height: 480px;
		}
		@include responsive($tabletSmall, max) {
			min-height: 380px;
		}
		@include responsive($mobile, max) {
			min-height: 280px;
		}
		@include responsive($mobileSmall, max) {
			min-height: 200px;
		}
	}

	.page__header--container {
		.container {
			padding-top: 80px;
			padding-bottom: 80px;

			@include responsive($mobile, max) {
				padding-top: 60px;
				padding-bottom: 60px;
			}

			&.--breadcrumb {
				padding-top: 0 !important;
				padding-bottom: 0 !important;
			}
		}

		.col{
			&.--title,
			&.--text{
				width: 100%;
			}

			&.--title{
				> p{
					margin-top: 1.5em;
				}
			}

			&.--text{
				@include responsive($tablet){
					margin-top: 7em;
				}
			}
		}
	}

	div:not(.page__header--container) > .container {
		padding-top: 100px;
		padding-bottom: 100px;
		@include responsive($tablet, max) {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		@include responsive($mobile, max) {
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}

	.container {
		> .row-vertical {
			&.maxw840 {
				margin-left: auto;
				margin-right: auto;
			}
			> * {
				margin-bottom: 40px;
				&:last-child {
					margin-bottom: 0px;
				}
			}
			margin-bottom: 40px;
			&:last-child {
				margin-bottom: 0px;
			}
		}

		> .gallery-swiper {
			> .container {
				padding-top: 20px !important;
				padding-left: 0px !important;
				padding-right: 0px !important;
				padding-bottom: 0px !important;
			}
			.swiperGallery {
				/*max-width: 840px;*/
				overflow: hidden;
				// max-width: 1154px;
				// display:flex;flex-direction:row;align-items:flex-end;
				@include responsive($desk, max) {
					max-width: 840px;
					margin: 0 auto;
				}
				.swiper-wrapper {
					// max-width: 840px;
					// width:calc(100% - (157px * 2));
					// margin-right:157px;
					// padding:0 157px;
					@include responsive($desk, max) {
						margin-bottom: 80px;
					}
					.swiper-slide {
						max-width: 100%;
						padding: 0 157px;
						.gallery--item > img {
							margin: 0 auto;
						}
						@include responsive($desk, max) {
							padding: 0;
						}
					}
				}
				.swiper-navigation {
					position: absolute;
					width: 157px;
					padding-right: 30px;
					bottom: 0;
					top: auto;
					z-index: 10;
					left: 0;
					height: 100%;
					display: flex;
					align-items: flex-end;
					background-color: var(--white);
					// left:-157px;
					// left:-60px;
					@include responsive($desk, max) {
						// bottom:-80px;left:0px;
						bottom: 0;
						width: 100%;
						height: 57px;
					}
					@include responsive($tabletSmall, max) {
						padding-right: 0;
						margin-bottom: 0;
						justify-content: center;
						// left:0px;right:0;margin:0 auto;display:flex;justify-content:center;bottom:-50px;
					}
				}
			}
		}
	}

	.section--numbers {
		.col {
			padding-left: 0;
			padding-right: 0;
		}

		.row-numbers {
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 100px;
			@include responsive($tabletSmall, max) {
				margin-bottom: 70px;
			}
		}

		.row-player {
			display: block !important;
			margin-left: auto;
			margin-right: auto;
		}

		.wswyg--content a:not(.Btn){
			color: #8797b7;
		}
	}

	.section--swiper__quotes {
		.container {
			padding-top: 70px !important;
			@include responsive($tablet, max) {
				padding-top: 50px !important;
			}
		}
	}

	.section--related-posts {
		> .row-title {
			margin-bottom: 58px;
			@include responsive($tabletSmall, max) {
				margin-bottom: 48px;
			}
		}
	}

	.wswyg--content{
		h2{
			text-transform: uppercase;
		}
	}
}

.container {
	.section--title {
		text-transform: uppercase;
		margin-bottom: 0px;
	}
	.row .section--title {
		margin-bottom: 0px;
	}

	.row {
		margin-bottom: 40px;
		&:last-child {
			margin-bottom: 0px;
		}
	}
}
