.Page.OurEcosystem {

	.section--list-content {

		.container {
			padding-top: 0 !important;
		
			.row--results-list--menu {
				@include responsive ($tabletSmall, max) {
					// display:none !important;
				}
				.results-list--menu {
					display:flex;width:100%;border:1px solid var(--blue-20);border-radius:50px;
					height: 70px;align-items:center;justify-content: center;padding: 10px;
					.results-list--menu-item {
						display: flex;
						align-items: center;
						justify-content: center;
						width:calc(100% / 3);text-align: center;
						height:50px;border-radius:50px;cursor:pointer;
						transition:var(--default-transition);

						> span {
							color:var(--base);font-size:13px;font-weight:700;line-height:13px;
							padding: 0px 30px;text-transform:uppercase;transition:var(--default-transition);
							
							@include responsive ($tablet, max) {
								padding:18px 20px;
							}

							@include responsive($mobile, max){
								font-size: 10px;
							}
						}

						&:hover {
							background-color:var(--blue-20);
						}

						&.--active {
							background-color:var(--base);
							span {
								color:var(--white);
							}
						}
					}
				}
			}

			.row--results-list--intros {
				@include responsive ($tabletSmall, max) {
					// display:none !important;
				}

				> .w100{
					width: 100%;
				}
				
				.results-intro {
					display: none;

					&.--active {
						display:block;
					}
				}
			}

			.results--wrapper {
				min-width: 100%;
				padding-top:0 !important;
				margin-bottom:0 !important;

				.results-list--items--mobileTitle {
					@include responsive ($tabletSmall) {
						// display:none !important;
					}
					display:block;text-align:center;
					strong {
						text-transform:uppercase;font-size:22px;line-height:1.2;font-weight:700;
					}
				}
				.results-list--items--mobileIntro {
					@include responsive ($tabletSmall) {
						// display:none !important;
					}
					display:block;text-align:center;
				}
				.results-list--items-wrapper {
					// min-width: 100%;
					display:none;

					&.--active {
						display:flex;flex-direction:column;align-items:center;
						max-width: 945px;
						margin: 0 auto;

						> .results-list--items {
							display:flex;flex-wrap:wrap;justify-content:center;
						}
					}

					> a {
						margin-top:46px;
					}

					> a.Btn {
						margin-top:24px;
					}

					&.--members{
						width: 100%;
						
						@include responsive($tablet){
							max-width: 90%;
						}
						
						.results-list--items{
							gap: 1em;
							
							@include responsive($tablet){
								gap: 2em;
							}
						}

						.results-list--item{
							width: auto;
							padding: 0;

							&:not(:last-child){
								.results-list--item__content .results-list--item__text > .results-list--item__description2{
									&:after{
										content: '-';
										display: inline-block;
										padding-left: 0.5em;

										@include responsive($tablet){
											padding-left: 1em;
										}
									}
								}
							}

							&__img{
								border-radius: 50%;
								width: 100px;
								height: 100px;
								overflow: hidden;
								background: var(--grey-3);

								@include responsive($tablet){
									width: 180px;
									height: 180px;
								}

								img{
									object-fit: cover;
								}
							}

							// Ephemere styles for members cards!
							.results-list--item__img,
							.results-list--item__content .results-list--item__text > strong,
							.results-list--item__content .results-list--item__text > .results-list--item__description{
								display: none;
							}

							.results-list--item__content .results-list--item__text > .results-list--item__description2{
								@include fluid-type($mobileSmall, $deskXL, 23px, 32px);
								font-size: 32px;
								color: var(--blue-l);
								position: relative;
							}
						}
					}

					.results-list--item__img{
						img{
							height: 100%;
							width: auto;
							object-fit: contain;

							@include responsive($mobile, max){
								max-width: 100px;
							}
						}
					}

					.results-list--item {
						display:flex;flex-direction:column;
						padding:20px;width:255px;

						&[href]{
							.results-list--item__img{
								transition: transform 450ms $ease;
							}

							&:hover{
								.results-list--item__img{
									transform: scale(0.9);
								}
							}
						}

						.results-list--item__img {
							margin-bottom:20px;
						}
						.results-list--item__content {
							display:flex;flex-direction:column;

							strong {
								font-size:17px;line-height:22px;
							}
							div {
								font-size:15px;line-height: 19.5px;
							}
							.results-list--item__description2 {
								color:var(--blue-50);
							}
						}
					}

					&:not(#results--members) {
						.results-list--item {
							width:315px;
							.results-list--item__text {
								strong {
									min-height:44px;display:flex;align-items:center;
									margin-bottom:5px;justify-content: center;
								}
							}
							.results-list--item__img {
								min-height:96px;max-height:96px;display:flex;
								justify-content:center;align-items:center;
							}
						}
					}

					@include responsive ($tabletSmall, max) {
						margin-bottom:70px;
					}
				}
			}
		}
	}

}

	.container {
		.section--title {
			text-transform: uppercase;
			margin-bottom:60px;
		}
		.row .section--title {
			margin-bottom:60px;
		}

		.row {
			margin-bottom:40px;
		}
	}