h1, h2, h3, h4, h5, h6{
   font-weight: 700;
   font-family: var(--main-font);
}

h1,
.xl {
   // line-height: 1.05;
   margin-bottom: 0;
   // font-size: 16vw;
   font-size:90px;
   line-height:1.1em;
   @include fluid-type($mobileSmall, $deskXL, 48px, 80px);
   // @include responsive($tabletSmall) {
   //    @include font-vw(100);
   // }

   @media (max-width:$mobile), (min-width:$tabletSmall) and (max-width:$tablet) {
      word-wrap:break-word;hyphenate-character:'-';hyphens: auto;
   }

   .subtitle + & {
      margin-top: 0.1em;
   }

   > .--oh{
      &:not(:first-child){
         margin-top: -0.15em;
      }
   }
}

h2 {
   // font: 400 32px var(--main-font);
   margin-bottom: 20px;
   font-size:64px;
   line-height:1.1em;
   @include fluid-type($mobileSmall, $deskXL, 40px, 64px);
   // @include responsive($tabletSmall){
   //   @include font-vw(32);
   // }
}

h3 {
   // font: 400 24px var(--main-font);
   margin-bottom: 15px;
   font-size:40px;
   line-height:1.1em;
   @include fluid-type($mobileSmall, $deskXL, 27px, 40px);
   // @include responsive($tabletSmall){
   //   @include font-vw(24);
   // }
}

h4 {
   // font: 400 22px var(--main-font);
   margin-bottom:15px;

   font-size:36px;
   line-height:1.1em;
   @include fluid-type($mobileSmall, $deskXL, 24px, 36px);
   // @include responsive($tabletSmall){
   //   @include font-vw(22);
   // }
}

h5 {
   // font: 400 20px var(--main-font);
   margin-bottom:15px;
   font-size:32px;
   line-height:1.1em;
   @include fluid-type($mobileSmall, $deskXL, 19px, 32px);

   // @include responsive($tabletSmall){
   //   @include font-vw(20);
   // }
}

h6 {
   // font: 400 18px var(--main-font);
   margin-bottom:0;
   font-size:27px;
   line-height:1.1em;
   @include fluid-type($mobileSmall, $deskXL, 16px, 27px);
   // @include responsive($tabletSmall){
   //   @include font-vw(18);
   // }
}

sup {
   vertical-align: top;
   // filter: opacity(0.95);

   @include responsive($tabletSmall, max) {
      font-size: 50px;
   }

   // @include responsive($tabletSmall) {
   //    @include font-vw(50);
   // }
}

.subtitle {
   // filter: opacity(0.5);
   letter-spacing: -0.03em;
   font-size: 20px;

   // @include responsive($tabletSmall) {
   //    @include font-vw(20);
   // }
}

strong {
   font-weight: 700;
}

em{
   font-style: italic;
}

.font40, .font40 > * {
   font-size: 40px;
   line-height: 1.3;
   @include fluid-type($mobileSmall, $deskXL, 27px, 40px);
}
.font36, .font36 > * {
   font-size: 24px;
   line-height: 1.3;
   @include fluid-type($mobileSmall, $deskXL, 24px, 36px);
}
.font30, .font30 > * {
   font-size: 30px;
   line-height: 1.3;

   @include fluid-type($mobileSmall, $deskXL, 20px, 30px);
}
.font28, .font28 > * {
   font-size: 28px;
   line-height: 1.3;

   @include fluid-type($mobileSmall, $deskXL, 20px, 28px);
}
.font26, .font26 > * {
   font-size: 26px;
   line-height: 1.3;

   @include fluid-type($mobileSmall, $deskXL, 18px, 26px);
}
.font22, .font22 > * {
   font-size: 22px;
   line-height: 1.3;

   @include fluid-type($mobileSmall, $deskXL, 18px, 22px);
}
.font20, .font20 > * {
   font-size: 20px;
   line-height: 1.3;

   @include fluid-type($mobileSmall, $deskXL, 16px, 20px);
}

.weight300, .weight300 > * {
   font-weight:300;
}
.weight100, .weight100 > * {
   font-weight:100;
}

p {
   font-size: 18px;
   line-height: 1.3;

   @include fluid-type($mobileSmall, $deskXL, 15px, 18px);

   // @include responsive($tabletSmall){
   //   @include font-vw(18);
   // }

   &.no__results {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      opacity: 0.2;

      @include responsive($tabletSmall){
        @include font-vw(32);
      }
   }
}

.wswyg--content {
   text-align: left;

   * { color: inherit }

   > * + * {
      margin-top: 30px;

      @include responsive($tabletSmall){
         margin-top: get-vw(30);
      }
   }

   ul,
   ol {
      padding-left: 30px;

      @include responsive($tabletSmall){
         padding-left: get-vw(30);
      }

      > li + li {
         margin-top: 1em;
      }

      code {
         margin-top: 1em;
         margin-bottom: 1em;
      }
   }

   ul{
      list-style: disc;
   }

   ol {
      list-style-type: decimal;
   }

   figure {
      margin: 40px auto;
      overflow: hidden;

      @include responsive($tabletSmall){
         margin: space(1) auto;
      }

      &:last-child {
         margin-bottom: 0px;
      }

      img {
         display: block;
      }
   }

   blockquote {
      padding-left: 30px;
      border-left: 2px solid var(--grey);

      @include responsive($tabletSmall){
         padding-left: space(0.5);
      }

      p{
         font-weight: 500;
         font-size: 28px;
         line-height: 1.4;
         
         @include responsive($tabletSmall){
            @include font-vw(32);
         }
      }
   }

   a:not(.Btn) {
      color: var(--links-color);
      font-weight: bold;
      text-decoration: underline;
   }
}

.word__wrapper {
   overflow: hidden;
   line-height: 0.85;
   padding-bottom: 0.3em;
   margin-bottom: -0.3em;
   
   .word {
     display: block;
   }
 }

 small{
   font-size: 0.65em;
}