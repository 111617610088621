.Footer {
   background: var(--base);
   color: var(--white);
   position: relative;
   bottom:0;
   left:0;
   z-index:1;
   width:100%;

   html.--dark & {
      background: var(--base-d);
   }

   svg > * {
      fill: var(--white);
   }

   .container {
      padding-top: 40px;
      padding-bottom: 40px;
      @include flex(center, space-between);

      &.--marquee {
         padding-top:60px;padding-bottom:60px;max-width:100% !important;padding-left:0 !important;padding-right:0!important;
         width:100%;text-transform:uppercase;white-space:nowrap;overflow:hidden;margin:0 auto;
         
         @keyframes scroll {
           100% {
             transform: translateX(-100%);
           }
         }

         > * {
            animation:scroll 25s linear infinite;
            display:inline-block;width:max-content;padding-left: 160px;
            // will-change:transform;animation: marquee 25s linear infinite;
         }

         /* Respect user preferences about animations */
         @media (prefers-reduced-motion: reduce) {
           .marquee h2, 
           .marquee span{
             animation-iteration-count: 1;
             animation-duration: 0.01; 
             /* instead of animation: none, so an animationend event is 
              * still available, if previously attached.
              */
             width: auto;
             padding-left: 0;
           }
         }
      }
      &.footer {
         align-items: flex-start;

         .Nav__container--footer {
            .Nav__main {
               padding:0 !important;
               .Nav__item {
                  display: block;text-align:left;
                  margin-left:0 !important;
                  margin-bottom:8px;
                  font-size:13px !important;
                  &:last-of-type {
                     margin-bottom:0;
                  }
                  .Nav__link {
                     font-size:13px !important;
                     transition:var(--default-transition);position:relative;
                     width:max-content;max-width:100%;
                     &.footer-col-menu__title {
                        font-size:11px !important;margin-bottom:20px;color:var(--grey-3);
                        text-transform:uppercase;font-weight:700;
                        @include responsive ($tablet, max) {
                           font-size:13px !important;
                        }
                     }
                     &:after {
                        content:"";transition:var(--default-transition);display:block;
                        position:absolute;bottom:-1px;left:0;right:0;width:0;height:1px;background-color: var(--white);
                     }
                     &:hover {
                        opacity:.7;
                        &:after {
                           width:100%;
                        }
                     }
                  }
               }
            }
         }
         .footer-col-contact {
            display:flex;flex-wrap:nowrap;
            .footer-col-contact--btn {
               display:flex;flex-direction:column;
               margin-right:32px;
               a {
                  margin-bottom:12px;
                  &:last-of-type {
                     margin-bottom:0 !important;
                  }
               }
            }
            .footer-col-contact--rsx {
               @include responsive ($tabletSmall, max) {
                  display:flex;align-items:center;
               }
               a {
                  display:flex;justify-content:center;align-items:center;
                  width:32px;height:32px;
                  transition:var(--default-transition);

                  &:hover {
                     opacity:0.7;
                  }

                  @include responsive ($tabletSmall, max) {
                     width:50px;height:50px;
                     img {
                        width:38px;
                     }
                  }
               }
            }
         }

         @include responsive($desk, max) {
            flex-wrap:wrap;justify-content:center;
            .footer-col {
               width:25%;padding:0 10px;margin-bottom:40px;
            }
         }

         @media (min-width:$tabletSmall) and (max-width:$desk) {
            .footer-col {
               width:25%;padding-right:12px;
            }
            .footer-col-contact {
               display:flex;width:max-content;max-width:100%;margin-bottom:0px;
               .footer-col-contact--btn {
                  flex-direction:row;
                  a {
                     margin-right:34px;margin-bottom:0;
                     &:last-of-type {
                        margin-right:0 !important;
                     }
                  }
               }
            }
         }
         
         @include responsive($tabletSmall, max) {
            .footer-col {
               width:100%;

               &.footer-col-logo {
                  width:100%;
                  .Brand img {
                     margin:0 auto;
                  }
               }
               &:not(.footer-col-logo):not(.footer-col-contact) {
                  display:flex;flex-direction:row;align-items:center;
                  nav {
                     width:100%;
                     .Nav__main {
                        display:flex;flex-wrap:wrap;
                        .Nav__item:first-of-type {
                           width:100%;
                        }
                        .Nav__item {
                           width:50%;
                        }
                     }
                  }
               }

               &.footer-col-contact {
                  width:100%;flex-wrap:wrap;margin-bottom: 0;
                  justify-content:flex-start;

                  .footer-col-contact--btn {
                  flex-direction:row;max-width:100%;flex-wrap:wrap;
                     a {
                        margin-right:34px;margin-bottom:16px !important;padding:17px 34px;
                        &:last-of-type {
                           margin-right:0 !important;
                        }
                     }
                  }
               }
            }
         }

         @media (max-width:440px) {
            flex-direction:column;
            &:not(.footer-col-logo):not(.footer-col-contact) {
                  .Nav__main {
                     .Nav__item {
                        width:100% !important;
                     }
                  }
            }
            // .footer-col-contact {
            //       flex-direction:column;
            //       .footer-col-contact--btn {
            //          flex-direction:column !important;margin-right:0;align-items:center;
            //          a {
            //             margin-right:0 !important;
            //          }
            //       }
            //       .footer-col-contact--rsx {
            //          display:flex;justify-content:space-evenly;
            //       }
            // }
         }
         @include responsive ($mobileSmall, max) {
            .footer-col-contact {
                  flex-direction:column;
                  .footer-col-contact--btn {
                     flex-direction:column !important;margin-right:0;align-items:center;
                     a {
                        margin-right:0 !important;
                     }
                  }
                  .footer-col-contact--rsx {
                     display:flex;justify-content:space-evenly;
                  }
            }
         }
      }
      &.footer-copyright {
         padding-top: 40px;
         padding-bottom: 40px;
         > div {
            display:flex;flex-wrap:nowrap;
            &.footer-copyright-col1 {
               .Nav__main {
                  padding:0 !important;display:flex;flex-wrap:nowrap;
                  .Nav__item {
                     margin-right:24px;
                     &:last-of-type {
                        margin-right:0 !important;
                     }
                     .Nav__link {
                        text-transform:none !important;font-size:11px;font-weight:300;
                        transition:var(--default-transition);position:relative;
                        &:after {
                           content:"";transition:var(--default-transition);display:block;
                           position:absolute;bottom:-1px;left:0;right:0;width:0;height:1px;background-color: var(--white);
                        }
                        &:hover, &.--active {
                           opacity:.7;
                           &:after {
                              width:100%;
                           }
                        }
                     }
                     @include responsive($mobile, max) {
                        margin-right:20px;
                     }
                  }
                  
                  @include responsive($mobileSmall, max) {
                     flex-direction:column;
                     .Nav__item {
                        margin-right:0;
                        margin-bottom:10px;
                     }
                  }
               }
            }
            &.footer-copyright-col2 {
               justify-content:flex-end;
               > * {
                  margin-right:24px;font-size:11px;font-weight:300;
                  &:last-child {
                     margin-right:0 !important;
                  }
               }
               @include responsive($tabletSmall, max) {
                  justify-content:flex-start;
               }
               @include responsive($mobileSmall, max) {
                  flex-direction:column;
                  > * {
                     margin-right:0;margin-bottom:10px;text-align:center;
                  }
                  > *:last-child {
                     margin-bottom:0;
                  }
               }
            }
            @include responsive($tabletSmall, max) {
               min-width:100%;margin-bottom:28px;justify-content:flex-start;
               &:last-child {
                  margin-bottom:0 !important;
               }
            }
            @include responsive($tabletSmall, max) {
               justify-content:flex-start;
            }
            @media (max-width:440px) {
               justify-content:center !important;
            }
         }
         @include responsive($tabletSmall, max) {
            flex-wrap:wrap;justify-content:center;
         }
      }
   }
}
